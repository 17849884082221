import { getStorage, ref } from "firebase/storage"
import React, { useEffect, useState } from "react"
import { BiStar } from "react-icons/bi"
import { FaFilePdf } from "react-icons/fa"
import { useStateValue } from "../../../context/StateProvider"
import { useAuthStatus } from "../../../utils/hooks/useAuth"
import { useCollection } from "../../../utils/hooks/useCollection"
import { capFirst } from "../../../utils/utils"

function WorkerProfile() {
  const [{ user }, dispatch] = useStateValue()
  const { data } = useAuthStatus()
  
  const { documents, error, isPending } = useCollection("rating", ["workerID", "==", user.userData.id])
  // Rating Star Calculation
  const [rateStar, setRateStar] = useState('')
  const oneStar = documents?.filter((r) => r.rateNum === 1).length
  const twoStar = documents?.filter((r) => r.rateNum === 2).length
  const threeStar = documents?.filter((r) => r.rateNum === 3).length
  const fourStar = documents?.filter((r) => r.rateNum === 4).length
  const fiveStar = documents?.filter((r) => r.rateNum === 5).length
  useEffect(() => {
    return () => {
      // cancel any outstanding tasks or subscriptions here
    }
  }, [])
  useEffect(() => {
      const oneTotal = oneStar * 1
      const twoTotal = twoStar * 2
      const threeTotal = threeStar * 3
      const fourTotal = fourStar * 4 
      const fiveTotal = fiveStar * 5 
    
      const totalClicks = (oneStar + twoStar + threeStar + fourStar + fiveStar)
      const totalStars = (oneTotal + twoTotal + threeTotal + fourTotal + fiveTotal)
      const avgStars = (totalStars / totalClicks)
      
      setRateStar(avgStars.toPrecision(3))
  }, [documents])

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Applicant Information
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Personal details and application
        </p>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-gray-50 cursor-pointer px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Full Name</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {(data?.workerSName ? capFirst(data?.workerSName) : "") +
                " " +
                (data?.workerLName ? capFirst(data?.workerLName) : "") +
                " " +
                (data?.workerFName ? capFirst(data?.workerFName) : "")}
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Role</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {user?.userData?.role}
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Review</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {
                documents?.length > 0 ?  (<p className="mt-1 max-w-2xl text-sm text-gray-500 flex flex-row">
                <span>{documents?.length || 0} reviews</span>
                <BiStar size={20} color='#FFDF00'/> {rateStar || '0'}
                </p>) : <p>no rating</p>

              }
            </dd>
          </div>
          <div className="bg-gray-50 cursor-pointer px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Email address</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {data?.workerEmail ? data?.workerEmail : ""}
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Phone Number</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {data?.workerMob ? data?.workerMob : ""}
            </dd>
          </div>
          <div className="bg-gray-50 cursor-pointer px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">About</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"></dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Attachments</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                {data?.workerAttaches?.length > 0 ? '' : 'No Attachment'}
                {data &&
                  data?.workerAttaches?.map((file, index) => {
                    const storage = getStorage()
                    const fileName = ref(storage, file)
                    var strArray = fileName.name.split(".")
                    return (
                      <li
                        className="pl-3 pr-4 py-3 flex items-center justify-between text-sm cursor-pointer"
                        key={index}
                      >
                        <div className="w-0 flex-1 flex items-center">
                          <FaFilePdf
                            className="flex-shrink-0 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="ml-2 flex-1 w-0 truncate">
                            {strArray[0]}
                          </span>
                        </div>
                        <div className="ml-4 flex-shrink-0">
                          <a
                            href={file}
                            target="_blank"
                            rel="noreferrer"
                            className="font-medium text-indigo-600 hover:text-indigo-500"
                          >
                            Download
                          </a>
                        </div>
                      </li>
                    )
                  })}
              </ul>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  )
}

export default WorkerProfile
