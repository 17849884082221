import { Timestamp } from "firebase/firestore"
import moment from "moment"
import { useCollection } from "./hooks/useCollection"


// Get User Type
export const getUserType = () =>{
    const options = [
        { value: 'client', label: 'Client' },
        { value: 'worker', label: 'Worker' }
      ]
      return options
} 
// Get Client Type
export const getClientType = () =>{
    const options = [
        { value: 'Organization', label: 'Organization' },
        { value: 'Individual', label: 'Individual' }
      ]
      return options
} 
// Get Service Time
export const getServiceTime = () =>{
    const options = [
        { value: 'Breakfast', label: 'Breakfast' },
        { value: 'Lunch', label: 'Lunch' },
        { value: 'Dinner', label: 'Dinner' },
        { value: 'Exceptional', label: 'Exceptional' }
      ]
      return options
} 
// Get worker Language 
export const getWorkerLang = () =>{
    const options = [
        { value: 'English', label: 'English' },
        { value: 'Amharic', label: 'Amharic' },
        { value: 'Afan Oromo', label: 'Afan Oromo' },
        { value: 'French', label: 'French' }
      ]
      return options
} 

// Get Firebase Time Stamp
export const getTimeStamp = (date) => {
  const convertedDate = new Timestamp(date.seconds, date.nanoseconds).toDate().toDateString()
  return convertedDate
 }

// Get Firebase Time Stamp Date (11/21/2022 16:00:00)
export const getTimeStampDate = (date) => {
  const convertedDate = new Timestamp(date.seconds, date.nanoseconds).toDate()
  return convertedDate 
}
  // Concatenate Class Name
export const getClassName =(...classes) => {
  return classes.filter(Boolean).join(" ")
}

// Convert Date & Time to String 
export const getDateTimeString = (date) => {
  if(date){
    return moment.utc(date.seconds*1000).format("YYYY-MM-DD")
  }
}
// Get Service Type Ref
export const getServiceTypeRef = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isPending, error, documents } = useCollection('service_type')
  if (error) {
      return null
  }
  if (!isPending) {
    const statusOptions = []
    documents?.map((document) => (
         statusOptions.push({value:document.srvTypeTitle, label:document.srvTypeTitle})
    ))
      return statusOptions
  } 
}
// Get Worker Preferable Language Ref
export const getPreferableLanRef = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isPending, error, documents } = useCollection('web_settings')
  if (error) {
      return null
  }
  if (!isPending) {
    const statusOptions = []
    if(documents?.length){

      documents[0]?.preferable_lang?.map((document) => (
           statusOptions.push({value:document, label:document})
      ))
    }
      return statusOptions
  } 
}
// Get Service Time Ref
export const getServiceTimeRef = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isPending, error, documents } = useCollection('web_settings')
  if (error) {
      return null
  }
  if (!isPending) {
    const statusOptions = []
    if(documents?.length){

      documents[0]?.service_time?.map((document) => (
           statusOptions.push({value:document, label:document})
      ))
    }
      return statusOptions
  } 
}
// First Letter Capitalize
export const capFirst = (str) => {
  return str[0]?.toUpperCase() + str?.slice(1)
}