import React, { useState } from "react"
import PhoneInput from "react-phone-input-2"
import { Link, useNavigate } from "react-router-dom"
import "react-phone-input-2/lib/style.css"
import "./style.css"
import {
  collection,
  where,
  query,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore"
import { db } from "../../firebase.config"
import { toast } from "react-toastify"
import { CgSpinner } from "react-icons/cg";

// firebase
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth"
import { useStateValue } from "../../context/StateProvider"
import { actionType } from "../../context/reducer"
import Header from "../../components/Header"
import logo from "../../assets/img/logo/WaiterApp_Icon.png"

function Login() {
  const clientRef = collection(db, "client")
  const workerRef = collection(db, "worker")

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [phone, setPhone] = useState("")
  const [showOtp, setShopOtp] = useState(false)
  const [otp, setOtp] = useState("")
  const [sendOtp, setSendOtp] = useState(true)
  const [role, setRole] = useState("")
  const [{ user }, dispatch] = useStateValue()

  const auth = getAuth()

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // eslint-disable-next-line no-undef
          onSignInSubmit()
        },
      },
      auth
    )
  }
  const requestOTP = async (e) => {
    e.preventDefault()
    if (phone) {
      const plusSign = "+"
      const phoneUpdate = plusSign.concat(phone)
      // check user role
      const q = query(workerRef, where("workerMob", "==", phoneUpdate))
      const queryWorkerSnapshot = await getDocs(q)
      // Check user role
      const client_query = query(
        clientRef,
        where("clientMob", "==", phoneUpdate)
      )
      const queryClientSnapshot = await getDocs(client_query)
      if (!queryClientSnapshot.empty) {
        setRole("client")
      }
      if (!queryWorkerSnapshot.empty) {
        setRole("worker")
      }
      if (!queryWorkerSnapshot.empty || !queryClientSnapshot.empty) {
        toast.success("OTP code sent")
        setSendOtp(false)
        setShopOtp(true)
        generateRecaptcha()
        const appVerifier = window.recaptchaVerifier
        signInWithPhoneNumber(auth, phoneUpdate, appVerifier)
          .then((confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult
            // ...
          })
          .catch((error) => {
            // Error; SMS not sent
            console.log(error)
          })
      } else {
        toast.error("User not Found Please Create Account")
        //  setExist(false)
      }
    }
  }
  const getUserData = async (id, userData, role) => {
    let data = ""
    const docRef = doc(db, role, id)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      data = docSnap.data()
    } else {
      console.log("not found")
    }
    let updateUserData = { userData, data }
    dispatch({
      type: actionType.SET_USER,
      user: updateUserData,
    })
    localStorage.setItem("user", JSON.stringify(updateUserData))
    navigate("/")
    navigate(0)
  }

  const otpOnChange = async (e) => {
    const otp = e.target.value
    setOtp(otp)
  }
  const verifyOTP = async () => {
    setLoading(true)
    if (otp.length === 6) {
      window?.confirmationResult?.confirm(otp)
        .then((result) => {
          // User signed in successfully.
          setLoading(false)
          const user = result.user
          if (role === "worker") {
            const userData = {
              id: user.uid,
              displayName: user.displayName,
              providerData: user.providerData[0],
              photoURL: user.photoURL,
              role: "worker",
            }
            const workerData = getUserData(userData.id, userData, role)
          }
          if (role === "client") {
            const userData = {
              id: user.uid,
              displayName: user.displayName,
              providerData: user.providerData[0],
              photoURL: user.photoURL,
              role: "client",
            }

            const clientData = getUserData(userData.id, userData, role)
            // navigate(0)
          }
          return true
        })
        .catch((error) => {
          toast.error("Invalid verification code", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        })
    }
  }

  return (
    <>
    <Header sticky={true}/>
    <div className="flex w-full h-screen mt-10">
      <div className="w-full flex items-center justify-center lg:w-1/2">
        <div className=" w-11/12 max-w-[700px] px-10 py-20 rounded-3xl bg-white border-2 border-gray-100">
          <form onSubmit={requestOTP}>
            <h1 className="text-3xl md:text-5xl font-semibold">Welcome Back</h1>
            <p className="font-medium text-lg text-gray-500 mt-4">
              Please enter your phone to login.
            </p>
            <div className="mt-8">
              <div className="flex flex-col">
                <label className="text-lg font-medium">Phone</label>
                <PhoneInput
                  placeholder="911309125"
                  country={"et"}
                  enableSearch={true}
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                />
              </div>
              {showOtp && (
                <>
                  <div className="flex flex-col mt-4">
                    <label className="text-lg font-medium">OTP Code</label>
                    <input
                      maxLength={6}
                      country='et'
                      name="otp"
                      id="otp"
                      onChange={otpOnChange}
                      defaultValue={otp}
                      className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent"
                    />
                  </div>
                </>
              )}

              {/* <div className="mt-8 flex justify-between items-center">
                <button className="font-medium text-base text-orange-500">
                  Forgot password
                </button>
              </div> */}
              <div className="mt-8 flex flex-col gap-y-4">
                {sendOtp && (
                  <>
                    <button className="active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01]  ease-in-out transform py-4 bg-orange-500 rounded-xl text-white font-bold text-lg">
                      Send OTP
                    </button>

                  </>
                )}
              </div>
            </div>
            <div id="recaptcha-container"></div>
          </form>
          {!sendOtp && (
            <>
              <button
                  onClick={verifyOTP}
                  className="bg-orange-500 w-full flex gap-1 items-center justify-center py-3 text-white rounded-xl font-bold text-lg active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01]  ease-in-out transform"
                >
                  {loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                  )}
                  <span>Submit</span>
              </button>
              
                </>
      

          )}
          <div className="mt-8 flex justify-center items-center flex-col md:flex-row">
            <p className="font-medium text-base">Don't have an account?</p>
            <Link to="/register">
              <button
                className="ml-2 font-medium text-base text-orange-500"
              >
                Sign up
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="hidden relative w-1/2 h-full lg:flex items-center justify-center bg-gray-100">
      <div className="w-60 h-60 rounded-full bg-gradient-to-tr from-primary to-secondaryLight animate-spin" />
        <div className="absolute w-48 h-48 rounded-full z-10">
          <img src={logo} alt="" />
        </div>
        <div className="w-full h-1/2 absolute bottom-0 bg-white/10 backdrop-blur-lg z-0" />
      </div>
    </div>
    </>
  )
}

export default Login
