import React from "react"
import TermsList from "../../components/terms"


function terms() {
  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
          Terms and Conditions
          </h2>
         <TermsList/>
        </div>
      </div>
    </div>
  )
}

export default terms
