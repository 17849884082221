import { getAuth } from "firebase/auth"
import { useState, useEffect } from "react"
import { toast } from "react-toastify"
import { useAuthStatus } from "../../../utils/hooks/useAuth"
import { useDocument } from "../../../utils/hooks/useDocument"
import { useFirestore } from "../../../utils/hooks/useFirebase"
import Spinner from "../../spinner/spinner"
import Select from "react-select"
import { getClientType } from "../../../utils/utils"

function ClientProfile() {
  const clientTypeOptions = getClientType()
  const { data, userData } = useAuthStatus()

  const [formData, setFormData] = useState({
    clientSName: "",
    clientLName: "",
    clientType: "Individual",
    clientEmail: "",
  })

  const { clientSName, clientLName, clientType, clientEmail } = formData
  // Fetch listing to edit
  useEffect(() => {
    if (data) {
      setFormData(data)
    }
  }, [data])
  const { updateDocument, response } = useFirestore("client")

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }))
  }
  const handleChange = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      clientType: selectedOption.value,
    }))
  }
  const onSubmit = async (e) => {
    e.preventDefault()
    await updateDocument(userData.uid, formData)
    toast.success("Profile Update successfully", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
  return (
    <div>
      {data ? (
        <div className="space-y-16 divide-y divide-gray-200 py-4 w-full container mx-auto">
          <div className="p-8 shadow max-w-4xl mx-auto">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Personal Information
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Use a permanent address where you can receive mail.
              </p>
            </div>
            <form onSubmit={onSubmit}>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={clientSName}
                      onChange={onChange}
                      id="clientSName"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={clientLName}
                      onChange={onChange}
                      id="clientLName"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      disabled
                      id="clientEmail"
                      name="clientEmail"
                      type="email"
                      value={clientEmail}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              <div className="sm:col-span-3">
                  <label className="text-base font-medium">Client Type {clientType}</label>
                  <Select
                    id="clientType"
                    onChange={handleChange}
                    isClearable={false}
                    isSearchable={false}
                    classNamePrefix="select"
                    options={clientTypeOptions}
                    value={
                      clientType === "Organization" ? clientTypeOptions[0] : clientTypeOptions[1]
                    }
                  />
                
              </div>
              </div>
              <div className="flex justify-end mt-2">
                <button className="p-2 bg-primary rounded-xl text-white">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <Spinner w="50" h="50" />
      )}
    </div>
  )
}

export default ClientProfile
