import React from "react"
import { useParams } from "react-router-dom"
import { useDocument } from "../../../utils/hooks/useDocument"
import Header from "../../Header"
import AssignedUser from "./assigned"

function Assigned() {
  const params = useParams()
  const { isPending, error, documents } = useDocument("orders", params.id)
  return (
    <>
    <Header sticky={true}/>
    <div className="my-36"></div>
      {" "}
      {documents?.assign.length > 0 && <h1 className="text-center text-lg font-semibold">{documents?.assign.length} Worker Assigned</h1> }
      {documents?.assign.length > 0 ? (
        documents &&
        documents?.assign.map((user,index) => (
            <AssignedUser key={index} id={user.value} orderID={params.id} clientID={documents.orderUserId} />
        ))
      ) : (
        <div className="w-full flex flex-col items-center justify-center">
          <p className="text-xl text-headingColor font-semibold my-2">
           There is no assigned worker
          </p>
        </div>
      )}
    </>
  )
}

export default Assigned
