import React, { useEffect, useState } from "react"
import { motion } from "framer-motion"
import { useStateValue } from "../context/StateProvider"
import { actionType } from "../context/reducer"
import { fetchCart } from "../utils/fetchLocalStorageData"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import Spinner from "./spinner/spinner"
import Skeleton from "./skeleton/Skeleton"

const RowContainer = ({ data}) => {
  const [items, setItems] = useState([])

  const [{ cartItems, user }, dispatch] = useStateValue()

  const addtocart = () => {
    let cartData = fetchCart()

    let current = ""
    if (items.length > 0) {
      current = items
    } else {
      current = cartData
    }
    dispatch({
      type: actionType.SET_CARTITEMS,
      cartItems: current,
    })
    localStorage.setItem("cartItems", JSON.stringify(current))
  }

  useEffect(() => {
    addtocart()
  }, [items])
  const addCartItem = (cartItems, serviceToAdd) => {
    serviceToAdd.qty = 1
    const existingCartItem = cartItems.find(
      (cartItem) => cartItem.id === serviceToAdd.id
    )
    if (user?.userData.role === "worker") {
      toast.error("you can't add cart", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })

      return
    }
    if (existingCartItem) {
      toast.error("cart already existed!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      return cartItems.map((cartItem) =>
        cartItem.id === serviceToAdd.id
          ? { ...cartItem, quantity: cartItem.quantity + 1 }
          : cartItem
      )
    } else {
        // Remove unused Field
      const newServiceToAdd = serviceToAdd
      delete newServiceToAdd.serviceStatus
      delete newServiceToAdd.createdAt
      setItems([...cartItems, newServiceToAdd])
      toast("Cart Add Successfully!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }

    return [...cartItems, { ...serviceToAdd, quantity: 1 }]
  }

  if (!data) {
    return  <>
    <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
      <Skeleton type={'feed'} size={4} />
    </div>
    </>
  }

  return (
    <>
      <div className="bg-white" id="request">
        <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="sr-only">Service</h2>
          <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
            {data && data.length > 0 ? (
              data.map((item) => (
                <div
                  key={item.id}
                  className="group relative bg-white border border-gray-200 rounded-lg flex flex-col overflow-hidden"
                >
                  <div className="bg-gray-200 group-hover:opacity-100 sm:aspect-none sm:h-96">
                    <img
                      src={item.serviceIcon}
                      alt={item.serviceTitle}
                      className="w-full h-full object-center object-cover sm:w-full sm:h-full"
                    />
                  </div>
                  <div className="flex-1 p-4 space-y-2 flex flex-col">
                    <h3 className="text-sm font-medium text-gray-900">
                      <Link to={`/category/${item.serviceType}/${item.id}`}>
                        <span aria-hidden="true" className="absolute inset-0" />
                        {item.serviceTitle}
                      </Link>
                    </h3>
                    <p className="text-sm text-gray-500">{item.serviceDesc}</p>
                    <div className="flex-1 flex flex-col justify-end">
                      <p className="text-sm italic text-gray-500">
                        {item.serviceType}
                      </p>
                      <p className="text-base font-medium text-gray-900">
                        {item.servicePrice}
                      </p>
                    </div>
                    <div className="mt-6">
                      <motion.div
                        whileHover={{ scale: 0.9 }}
                        className="relative flex bg-gray-100 border border-transparent rounded-md py-2 px-8 items-center justify-center text-sm font-medium text-gray-900 hover:bg-gray-200 cursor-pointer"
                        onClick={() => addCartItem(cartItems, item)}
                      >
                        {" "}
                        Add to cart
                        <span className="sr-only">, {item.serviceTitle}</span>
                      </motion.div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full flex flex-col items-center justify-center">
                {/* <img src={NotFound} className="h-340" /> */}
                <p className="text-xl text-headingColor font-semibold my-2">
                  Service Not Available
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default RowContainer
