import React from "react"
import {
  MdAddChart,
  MdArrowDropDown,
  MdArrowLeft,
  MdArrowUpward,
  MdDangerous,
} from "react-icons/md"
import { useStateValue } from "../../../context/StateProvider"
import { useAuthStatus } from "../../../utils/hooks/useAuth"
import { useCollection } from "../../../utils/hooks/useCollection"
import Spinner from "../../spinner/spinner"

function OrderStat() {
  const [{ user }] = useStateValue()
  console.log(user)

  const { isPending, error, documents } = useCollection("orders", [
    "orderUserId",
    "==",
    user?.userData?.id,
  ])
  console.log(documents?.filter((data) => data.orderStatus === "Paid").length)

  const stats = [
    {
      id: 1,
      name: "Total Orders",
      stat: documents?.length,
      icon: MdAddChart,
      bgColor: "bg-indigo-500",
      changeType: "increase",
    },
    {
      id: 2,
      name: "Pending Orders",
      stat: documents?.filter((data) => data.orderStatus === "Pending").length,
      icon: MdAddChart,
      bgColor: "bg-indigo-500",
      changeType: "increase",
    },
    {
      id: 3,
      name: "Paid Orders",
      stat: documents?.filter((data) => data.orderStatus === "Paid").length,
      icon: MdAddChart,
      bgColor: "bg-indigo-500",
      changeType: "increase",
    },
    {
      id: 3,
      name: "Rejected",
      stat: documents?.filter((data) => data.orderStatus === "Rejected").length,
      icon: MdDangerous,
      bgColor: "bg-red-500",
      changeType: "increase",
    },
  ]
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }
  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        {documents ? (
          stats.map((item, index) => (
            <div
              key={index}
              className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
            >
              <dt>
                <div className="absolute rounded-md p-3">
                  <item.icon
                    className={classNames(
                      item.bgColor === null ? "bg-indigo-500" : item.bgColor,
                      "w-8 h-8 text-white"
                    )}
                    aria-hidden="true"
                  />
                </div>
                <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                  {item.name}
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline">
                <p className="text-2xl font-semibold text-gray-900">
                  {item.stat}
                </p>
                <p
                  className={classNames(
                    item.changeType === "increase"
                      ? "text-green-600"
                      : "text-red-600",
                    "ml-2 flex items-baseline text-sm font-semibold"
                  )}
                >
                  {item.changeType === "increase" ? (
                    <MdArrowUpward
                      className="self-center flex-shrink-0 h-5 w-5 text-green-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <MdArrowDropDown
                      className="self-center flex-shrink-0 h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  )}

                  <span className="sr-only">
                    {item.changeType === "increase" ? "Increased" : "Decreased"}{" "}
                    by
                  </span>
                  {item.change}
                </p>
              </dd>
            </div>
          ))
        ) : (
          <Spinner w={50} h={50} />
        )}
      </dl>
    </div>
  )
}

export default OrderStat
