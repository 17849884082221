 import { Navigate, Outlet } from 'react-router-dom'
import { useAuthStatus } from '../../utils/hooks/useAuth'
import Spinner from '../spinner/spinner'

const PrivateRoute = () => {
  const { loggedIn, checkingStatus } = useAuthStatus()

  if (checkingStatus) {
    return <Spinner w="100" h="100"/>
  }

  return loggedIn ? <Outlet /> : <Navigate to='/register' />
}

export default PrivateRoute
