/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react"
import { doc, onSnapshot } from "firebase/firestore"
import { db } from "../../firebase.config"

export const useDocument = (table, id) => {
  const [documents, setDocuments] = useState(null)
  const [error, setError] = useState(null)
  const [isPending, setPending] = useState(false)

  useEffect(() => {
    
    // LISTEN (REALTIME)
    setPending(true)
    const unsub = onSnapshot(doc(db, table, id), (doc) => {
        setDocuments({ id: doc.id, ...doc.data() })
    })
    setPending(false)

    return () => {
      unsub()
    }
  }, [id, table])

  return { documents, error, isPending }
}
