import { useEffect, useState, useRef } from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'
import { useStateValue } from '../../context/StateProvider'
import { db } from '../../firebase.config'
import { actionType } from '../../context/reducer'
import { useNavigate } from 'react-router-dom'

export const useAuthStatus = () => {
  const [loggedIn, setLoggedIn] = useState(false)
  const navigate = useNavigate()
  const auth = getAuth()
  const [checkingStatus, setCheckingStatus] = useState(true)
  const [data, setData] = useState(null)
  const isMounted = useRef(true)
  const [userData, setUserData] = useState(null)
  const [{ user }, dispatch] = useStateValue()
  const role = user?.userData?.role
  const getUser = async (id) => {
    try{

      const docRef = doc(db, role, id)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        setData(docSnap.data())
      } else {
        navigate('/')
        auth.signOut()
        localStorage.clear()
        dispatch({
          type: actionType.SET_USER,
          user: null,
        })
      }
    } catch(error) {
       console.log(error)
    }
  }

  useEffect(() => {
    if (isMounted) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          console.log("user logged")
          setUserData(user)
          setLoggedIn(true)
          getUser(user.uid)
        }
        else{
          console.log("user Logout")
        }
        setCheckingStatus(false)
      })
    }

    return () => {
      isMounted.current = false
    }
  }, [isMounted])

  return { loggedIn, checkingStatus, userData, data }
}

