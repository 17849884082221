import { fetchUser,fetchCart } from "../utils/fetchLocalStorageData";

const userInfo = fetchUser();
const initialCart = fetchCart()


export const initialState = {
  user: userInfo,
  serviceItems: null,
  cartShow: false,
  cartItems: initialCart,
};
