import React from 'react'
import OrderInvoice from '../../components/client/order/OrderInvoice'
import Header from '../../components/Header'
// Client Invoice View
function InvoiceView() {
  return (
    <>
        <Header sticky={true}/>
        <div className="mt-24"></div>
        <OrderInvoice/>
    </>
  )
}

export default InvoiceView