import React, { useEffect, useState, useNagiate, useRef, Fragment } from "react"
import { MdOutlineKeyboardBackspace } from "react-icons/md"
import { RiRefreshFill } from "react-icons/ri"
import { Link, useNavigate } from "react-router-dom"
import { Dialog, Transition } from "@headlessui/react"
import { FaWindowClose } from "react-icons/fa"

import { motion } from "framer-motion"
import { useStateValue } from "../context/StateProvider"
import { actionType } from "../context/reducer"
import EmptyCart from "../img/emptyCart.svg"
import CartItem from "./CartItem"
import { fetchCart } from "../utils/fetchLocalStorageData"

const CartContainer = ({show, handleChange}) => {
  const [{ cartShow, cartItems, user }, dispatch] = useStateValue()
  const navigate = useNavigate()
  const [flag, setFlag] = useState(1)
  const [tot, setTot] = useState(0)
  const cartItemsData = fetchCart()
  const showCart = () => {
    dispatch({
      type: actionType.SET_CART_SHOW,
      cartShow: !cartShow,
    })
  }

  useEffect(() => {
    let totalPrice = cartItems.reduce(function (accumulator, item) {
      return accumulator + item.qty * item.price
    }, 0)
    setTot(0)
  }, [tot, flag, cartItems])

  const clearCart = () => {
    localStorage.setItem("cartItems", JSON.stringify([]))
    dispatch({
      type: actionType.SET_CARTITEMS,
      cartItems: [],
    })
  }

  const handleCheckOut = () => {
    handleChange()
    navigate("/request")
  }
  const handleRequest = () => {
    navigate("/register")
  }
  function useOutsideCart(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          dispatch({
            type: actionType.SET_CART_SHOW,
            cartShow: false,
          })
          
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRefCart = useRef(null);
  useOutsideCart(wrapperRefCart);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleChange} >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Service cart
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={handleChange}
                          >
                            <span className="sr-only">Close panel</span>
                            <FaWindowClose
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>

                      {cartItemsData && cartItemsData.length > 0 ? (
                        <div className="mt-8">
                          <div className="flow-root">
                            <ul className="-my-6 divide-y divide-gray-200">
                              {/* cart Item */}
                              {cartItemsData &&
                                cartItemsData.length > 0 &&
                                cartItemsData.map((item, index) => (
                                  <CartItem
                                    key={index}
                                    item={item}
                                    setFlag={setFlag}
                                    flag={flag}
                                  />
                                ))}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <p>no service</p>
                      )}
                    </div>

                    <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                      <div className="mt-6">
                        <div onClick={handleCheckOut} className="flex items-center justify-center rounded-md border border-transparent bg-orange-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-secondary cursor-pointer"
                        >
                          Checkout
                        </div>
                      </div>
                      <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                        <p>
                          or
                          <button
                            type="button"
                            className="font-medium text-indigo-600 hover:text-indigo-500 ml-2"
                            onClick={handleChange}
                          >
                            Continue 
                            <span aria-hidden="true"> &rarr;</span>
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default CartContainer
