import { useEffect, useRef, useState } from "react"
import { collection, onSnapshot, where, query, orderBy } from "firebase/firestore"
import { db } from "../../firebase.config"

export const useCollection =  (table, _query, _order) => {
  const [documents, setDocuments] = useState(null)
  const [error, setError] = useState(null)
  const [isPending, setPending] = useState(false)

  const q = useRef(_query).current
  const o = useRef(_order).current

  useEffect(() => {
    let ref =  collection(db, table)
    if (q) {
      ref = query(ref, where(...q))
    }
    if (o) {
      ref = query(ref, orderBy(...o))
    }
    // LISTEN (REALTIME)
    setPending(true)
    const unsub = onSnapshot(
      ref,
      (snapShot) => {
        const list = []
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() })
        })
        setDocuments(list)
        setError(null)
        setPending(false)
      },
      (error) => {
        console.log(error)
        setError("could not fetch the data")
      }
    )

    return () => {
      unsub()
    }
  }, [table])

  return { documents, error, isPending }
}
