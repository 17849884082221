import React, { useState, Fragment } from "react"
import { Link, useNavigate } from "react-router-dom"
import { collection, where, query, getDocs, serverTimestamp, setDoc, doc, getDoc } from "firebase/firestore"
import { db} from "../../firebase.config"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { toast } from "react-toastify"
import { useStateValue } from "../../context/StateProvider";
import { actionType } from "../../context/reducer";
import { Dialog, Transition } from '@headlessui/react'
import OtpInput from "otp-input-react";
// firebase
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  updateProfile,
} from "firebase/auth"
import Select from "react-select"
import { getClientType, getUserType, getServiceTypeRef } from "../../utils/utils"
import Header from "../../components/Header"
import TermsList from "../../components/terms"
import { CgSpinner } from "react-icons/cg"


function Register() {
  const serviceType = getServiceTypeRef()
  const userTypeOptions = getUserType()
  const clientTypeOptions = getClientType()

  const [exist, setExist] = useState(false)
  const [role, setRole] = useState()
  const [loading, setLoading] = useState(false)
  const [showForm, setShowForm] = useState(true)
  const [showOtp, setShopOtp] = useState(false)
  const [otp, setOtp] = useState('')
  const [clientType, setClientType] = useState("Organization")
  // Terms and Condition Modal
  const [isOpen, setIsOpen] = useState(false)
  const [{ user }, dispatch] = useStateValue();


  //state for form data
  const [formData, setFormData] = useState({
    SName: "",
    LName: "",
    email: "",
    password: "",
    password1: "",
    workerPosition: "",
    Mob: "251911309125",
    status: "Active",
  })
  const [error, setError] = useState({role:false,phoneNumber:false,workerPosition:false})
  const [phone, setPhone] = useState("")
  const navigate = useNavigate()
  const { SName, LName, email, Mob, status,workerPosition } = formData

  const handleChange = (selectedOption) => {
    // setFormData((prevState) => ({
    //   ...prevState,
    //   serviceStatus: selectedOption.value
    // }))
    if(selectedOption.value === 'client' || selectedOption.value === 'worker'){
      setRole(selectedOption.value)
    }
    if(selectedOption.value === 'Organization' || selectedOption.value === 'Individual'){
          setClientType(selectedOption.value)
    }
  }
  const handleChangeRef = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      workerPosition: selectedOption.value
    }))
  }
  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }))
  }
  const auth = getAuth()

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // eslint-disable-next-line no-undef
          onSignInSubmit()
        },
      },
      auth
    )
  }
  const handleInputData = (input) => async (e) => {
    // input value from the form
    const { value } = e.target
    const workerRef = collection(db, "worker")
    if (e.target.name === "workerMob") {
      // Create a query against the collection.
      const q = query(workerRef, where("workerMob", "==", value))
      const querySnapshot = await getDocs(q)
      if (!querySnapshot.empty) {
        setExist(true)
      } else {
        setExist(false)
      }
    }
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }))
  }
  const client = async(id) => {
   await setDoc(doc(db, "client", id), {
      clientSName: SName,
      clientLName: LName,
      clientType: clientType,
      clientEmail: email,
      clientMob : Mob,
      clientStatus : status,
      clientTimeStamp : serverTimestamp()
    })
    const userData = {
      id:auth.currentUser.uid,
      displayName:auth.currentUser.displayName,
      providerData:auth.currentUser.providerData[0],
      photoURL: auth.currentUser.photoURL,
      email: email,
      role : 'client'
     }
    dispatch({
      type: actionType.SET_USER,
      user: userData,
    });
    getUserData(userData.id, userData, role)
  }
 
  const worker = async(id) => {
    
   await setDoc(doc(db, "worker", id), {
      workerSName: SName,
      workerLName: LName,
      workerEmail: email,
      workerMob : Mob,
      workerPosition: workerPosition,
      workerEduc: [],
      workerLang: [],
      workerSkills: [],
      workerExp: {},
      workerStatus : status,
      workerTimeStamp : serverTimestamp()
    })
    const userData = {
      id:auth.currentUser.uid,
      displayName:auth.currentUser.displayName,
      providerData:auth.currentUser.providerData[0],
      photoURL: auth.currentUser.photoURL,
      email: email,
      role : 'worker'
     }
     dispatch({
      type: actionType.SET_USER,
      user: userData,
    });
    getUserData(userData.id, userData, role)
  }
  const onSignUpHandle = async (e) => {
    e.preventDefault()
    if(!role){
      setError((prevState) => ({
        ...prevState,
        role: true,
      }))
      return
    }else{
      setError((prevState) => ({
        ...prevState,
        role: false,
      }))
    }
    if(phone.length < 8){
      setError((prevState) => ({
        ...prevState,
        phoneNumber: true,
      }))
      return
    }else{
      setError((prevState) => ({
        ...prevState,
        phoneNumber: false,
      }))
    }
    if(role === 'worker'){
      if(!workerPosition){
        setError((prevState) => ({
          ...prevState,
          workerPosition: true,
        }))
        return
      }else{
        setError((prevState) => ({
          ...prevState,
          workerPosition: false,
        }))
      }
    }
  
    let formDataCopy = formData
    const plusSign = "+"
    formDataCopy.Mob = plusSign.concat(phone)
    formDataCopy.TimeStamp = serverTimestamp()
    delete formDataCopy.password1
    delete formDataCopy.password1
    // Check user role
    if (role === "worker") {
      const workerRef = collection(db, "worker")
      const q = query(workerRef, where("workerMob", "==", formDataCopy.Mob))
      const querySnapshot = await getDocs(q)
      if (!querySnapshot.empty) {
        toast.error("user already exist")
        return
      }
    } else if (role === "client") {
      const clientRef = collection(db, "client")
      const query_client = query(
        clientRef,
        where("clientMob", "==", formDataCopy.Mob)
      )
      const queryClientSnapshot = await getDocs(query_client)
      if (!queryClientSnapshot.empty) {
        toast.error("user already exist")
        return
      }
      
    }
    setShowForm(false)
    setShopOtp(true)
    if (phone) {
      const plusSign = "+"
      const phoneUpdate = plusSign.concat(phone)
        toast.success("OTP code sent")
        setShopOtp(true)
        generateRecaptcha()
        const appVerifier = window.recaptchaVerifier
        signInWithPhoneNumber(auth, phoneUpdate, appVerifier)
          .then((confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult
            // ...
          })
          .catch((error) => {
            // Error; SMS not sent
            console.log(error)
          })
         
    }
  }
  const getUserData = async (id, userData, role) => {
    let data = ""
    const docRef = doc(db, role, id)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      console.log(docSnap.data())
      data = docSnap.data()
    } else {
      console.log("not found")
    }
    let updateUserData = { userData, data }
    dispatch({
      type: actionType.SET_USER,
      user: userData,
    })
    localStorage.setItem("user", JSON.stringify(updateUserData))
    console.log(updateUserData)
    navigate('/')
    navigate(0)
  }
  const requestOTP = async (e) => {
    e.preventDefault()
    setLoading(true)
    if (otp.length === 6) {
      window.confirmationResult
        .confirm(otp)
        .then((result) => {
          // User signed in successfully.
          const user = result.user
           updateProfile(auth.currentUser, {
            displayName: SName + LName
          }).then(() => {
            if (role === 'worker') {
              worker(user.uid)
            }
            if (role === 'client') {
            client(user.uid)
            }
            setLoading(false)
          }).catch((error) => {
            console.log(error)
            // An error occurred
            // ...
          })

        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          toast.error("Invalid verification code", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        })
    }else{
      setLoading(false)
      toast.error("Enter verification code", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const termsConditionModal = () => {
    return(
      <Transition appear show={isOpen} as={Fragment}>
      <Dialog  as="div" className="relative z-50" onClose={()=>setIsOpen(true)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full md:max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-2xl font-bold leading-6 text-gray-900"
                >
                 Terms & Condition
                </Dialog.Title>
                <div className="mt-2 overflow-y-auto max-h-96">
                 <TermsList/>
                </div>
                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={() =>setIsOpen(false)}
                  >
                  I have read and agree with the above terms and conditions
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>

    )
  } 

  return (
    <>
    <Header sticky={true}/>
    <div className="p-8 md:p-20 shadow max-w-4xl mx-auto mt-24">
      {
        showForm &&   <form onSubmit={onSignUpHandle}>
          <h1 className="text-lg font-semibold text-center">Register</h1>
        <p className="font-medium text-base text-gray-400 mt-2">
          Please enter you details.
        </p>
        <div className="mt-8">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2">
            <div className="flex justify-center flex-col w-72">
              <label className="text-base font-medium">First Name</label>
              <input
                required
                value={SName}
                onChange={onChange}
                name="SName"
                id="SName"
                className="w-full border-2 border-gray-100 rounded-xl p-2 mt-1 bg-transparent"
                placeholder="First Name"
              />
            </div>
            <div className="flex justify-center flex-col mt-2 w-72">
              <label className="text-base font-medium">Last Name</label>
              <input
                value={LName}
                onChange={onChange}
                name="LName"
                id="LName"
                className="w-full border-2 border-gray-100 rounded-xl p-2 mt-1 bg-transparent"
                placeholder="Last Name"
              />
            </div>
            <div className="flex justify-center flex-col w-72">
              <label className="text-base font-medium">Email</label>
              <input
                value={email}
                onChange={onChange}
                name="email"
                id="email"
                type="email"
                className="w-full border-2 border-gray-100 rounded-xl p-2 mt-1 bg-transparent"
                placeholder="Enter your email"
              />
            </div>
            <div className="flex justify-center flex-col mt-2 w-72">
              <label className="text-base font-medium">Phone Number</label>{error.phoneNumber && <small className="text-red-500">This Field is Required</small> } 
              <PhoneInput
                placeholder="251911425363"
                country={"et"}
                enableSearch={true}
                value={phone}
                onChange={(phone) => setPhone(phone)}
              />
            </div>
            <div className="flex justify-center flex-col mt-2 w-72">
              <label className="text-base font-medium">User Type</label>{error.role && <small className="text-red-500">This Field is Required</small> } 
                <Select
                className="react-select"
                options={userTypeOptions}
                onChange={handleChange}
                />
            </div>
            {
             role === 'client' &&
            (<div className="flex justify-center flex-col mt-2 w-72">
              <label className="text-base font-medium">Client Type</label>
                <Select
                className="react-select"
                options={clientTypeOptions}
                defaultValue={clientTypeOptions[0]}
                onChange={handleChange}
                />
            </div>)
            }
            {
             role === 'worker' &&
            (<div className="flex justify-center flex-col mt-2 w-72">
              <label className="text-base font-medium">Position</label>{error.workerPosition && <small className="text-red-500">This Field is Required</small> } 
                <Select
                className="react-select"
                options={serviceType}
                onChange={handleChangeRef}
                />
            </div>)
            }
          </div>
          <div className="mt-8 flex justify-between items-center">
            <div>
              <input type="checkbox" id="remember" onClick={()=>setIsOpen(true)} required />
              <label className="ml-2 font-medium text-base" htmlFor="remember">
              Read and Agree with our <Link to='/terms' target="_blank" rel="noopener noreferrer" ><span className="underline text-blue-700">Terms & Condition</span> </Link>
              </label>
            </div>
          </div>
          <div className="mt-8 flex flex-col gap-y-4">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2">
              <button
                className="active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01]  ease-in-out transform py-3 bg-primary rounded-xl text-white font-bold text-lg"
              >
                Register
              </button>
            </div>
          </div>
          <div className="mt-8 flex justify-center items-center">
            <p className="font-medium text-base">Do You Have Account?</p>
            <Link to="/login">
              <button className="ml-2 font-medium text-base text-violet-500">
                Sign In
              </button>
            </Link>
          </div>
        </div>
      </form>

      }
      {showOtp && (
        <form onSubmit={requestOTP} className='w-80 mx-auto'>

                <div className="flex flex-col mt-4">
                  <label className="text-lg font-medium">OTP Code</label>
                   <OtpInput
                  value={otp}
                  onChange={setOtp}
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  autoFocus
                  className="opt-container"
                ></OtpInput>
                </div>
                <button className="bg-orange-500 w-full flex gap-1 items-center justify-center py-3 text-white rounded-xl font-bold text-lg active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01]  ease-in-out transform mt-3" type="submit">{loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                  )}Verify OTP</button>
        </form>
              )}
                <div id="recaptcha-container"></div>
                {termsConditionModal()}
    </div>
    </>
  )
}

export default Register



