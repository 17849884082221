import React from "react";

export default function Skeleton({ type, size }) {
  const FeedSkeleton = () => (
    <>
      <div className="bg-white" id="request">
        <div className="animate-pulse max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="sr-only">Service</h2>
          <div className="group relative bg-white border border-gray-200 rounded-lg flex flex-col overflow-hidden">
            <div className="aspect-w-3 aspect-h-4 bg-gray-200 group-hover:opacity-100 sm:aspect-none sm:h-96 w-[300px]">
              <div className="w-full h-full object-center object-cover sm:w-full sm:h-full bg-slate-200" />
            </div>
            <div className="flex-1 p-4 space-y-2 flex flex-col">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                <div className="h-2 bg-slate-200 rounded col-span-1"></div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                <div className="h-2 bg-slate-200 rounded col-span-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const TopSkeleton = () => (
    <div className="topSk">
      <div className="topSkIcon"></div>
      <div className="topSkIcon"></div>
      <div className="topSkIcon"></div>
      <div className="topSkImg"></div>
    </div>
  );

  const MenuSkeleton = () => (
    <div className="menuSk">
      <div className="menuSkItem"></div>
      <div className="menuSkItem"></div>
      <div className="menuSkItem"></div>
      <div className="menuSkItem"></div>
    </div>
  );

  const Circle = () => <div className="circle">loading...</div>;

  const CustomLoading = () => (
    <div className="custom">
      <div className="balls">
        <div className="ball ball1"></div>
        <div className="ball ball2"></div>
        <div className="ball ball3"></div>
      </div>
      <span className="customText">Loading...</span>
    </div>
  );

  if (type === "feed") {
    return Array.from({ length: size }, (_, i) => <FeedSkeleton key={i} />);
  }

  if (type === "top") {
    return <TopSkeleton />;
  }

  if (type === "menu") {
    return <MenuSkeleton />;
  }

  if (type === "circle") {
    return <Circle />;
  }

  if (type === "custom") {
    return <CustomLoading />;
  }

  return null;
}
