import { Link, NavLink, useNavigate, useParams } from "react-router-dom"
import { useState, useEffect } from "react"

import { useDocument } from "../../../utils/hooks/useDocument"
import { getTimeStamp } from "../../../utils/utils"
import Spinner from "../../spinner/spinner"
import Header from "../../Header"
import { useStateValue } from "../../../context/StateProvider"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function OrderDetail() {
  const params = useParams()
  const navigate = useNavigate()
  const { isPending, error, documents } = useDocument("orders", params.id)
  const [totalPrice, setTotalPrice] = useState(1)
  const [step, setStep] = useState(5)
  const [{ user}] = useStateValue()
  if(user.userData.role === 'worker') {
    navigate('/')
  }
  useEffect(() => {
    const totalPrice = documents?.orderService?.reduce(function (
      accumulator,
      item
    ) {
      return accumulator + item.qty * item.servicePrice
    },
    0)
    setTotalPrice(totalPrice)
    if (documents?.orderStatus === "Pending") {
      setStep(1)
    }
    if (documents?.orderStatus === "Accepted") {
      setStep(2)
    }
    if (documents?.orderStatus === "Confirmed") {
      setStep(3)
    }
    if (documents?.orderStatus === "Served") {
      setStep(4)
    }
    if (documents?.orderStatus === "Paid") {
      setStep(5)
    }
    if (documents?.orderStatus === "Rated") {
      setStep(5)
    }
  }, [documents?.orderService, documents?.orderStatus, params.id])

  return (
    <>
    <Header sticky={true}/>
      {!documents && (
        <div className="flex items-center justify-center mt-56">
          {/* <div
            className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
            role="status"
          > */}
          <Spinner w="100" h="100" />
          {/* </div> */}
        </div>
      )}
      {documents && (
        <div className="bg-gray-50 mt-24 md:mt-14">
          <div className="max-w-2xl mx-auto pt-16 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="bg-gray-50 rounded-lg py-6 px-4 sm:px-6 sm:flex sm:items-center sm:justify-between sm:space-x-6 lg:space-x-8">
              <dl className="divide-y divide-gray-200 space-y-6 text-sm text-gray-600 flex-auto sm:divide-y-0 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-x-6 lg:w-1/2 lg:flex-none lg:gap-x-8">
                <div className="flex justify-between sm:block">
                  <dt className="font-medium text-gray-900">Date placed</dt>
                  <dd className="sm:mt-1">
                    <time dateTime={documents.orderServiceDate}>
                      {documents.orderServiceDate}
                    </time>
                  </dd>
                </div>
                <div className="flex justify-between pt-6 font-medium text-gray-900 sm:block sm:pt-0">
                  <dt>Total amount</dt>
                  <dd className="sm:mt-1">{totalPrice || '-'}</dd>
                </div>
                <div className="flex justify-between pt-6 sm:block sm:pt-0">
                  <dt className="font-medium text-gray-900">Order number</dt>
                  <dd className="sm:mt-1 w-20">{documents.id}</dd>
                </div>
            
              </dl>
              <p className="flex flex-col">
                <Link
                  to={`/order/${documents.id}`}
                  className="w-full flex items-center justify-center bg-white my-2 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:mt-0"
                >
                  View Invoice
                  <span className="sr-only">for order {documents.id}</span>
                </Link>
                {
                  step > 4 &&   <Link
                  to={`/assigned/${documents.id}`}
                  className="w-full flex items-center justify-center bg-white mt-6 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:mt-0"
                >
                  View Assigned
                  <span className="sr-only">for order {documents.id}</span>
                </Link>
                }
              
              </p>
            </div>

            {/* Products */}
            <div className="mt-6">
              <h2 className="sr-only">Service purchased</h2>

              <div className="space-y-8">
                <div className="border-t border-gray-200 py-6 px-4 sm:px-6 lg:p-8">
                  <h4 className="sr-only">Status</h4>
                  <p className="text-sm font-medium text-gray-900">
                    {documents.orderStatus} on{" "}
                    <time dateTime={getTimeStamp(documents.orderDate)}>
                      {" "}
                      {getTimeStamp(documents.orderDate)}
                    </time>
                  </p>
                  <div className="mt-6" aria-hidden="true">
                    <div className="bg-gray-200 rounded-full overflow-hidden">
                      <div
                        className={classNames(
                          step > 4 ? "bg-green-400" : "bg-indigo-600",
                          "h-2 rounded-full"
                        )}
                        style={{ width: `calc((${step} * 2 + 1) / 10 * 100%)` }}
                      />
                    </div>
                    <div className="hidden sm:grid grid-cols-5 text-sm font-medium text-gray-600 mt-6">
                      <div className="text-indigo-600">Order placed</div>
                      <div
                        className={classNames(
                          step > 0 ? "text-red-600" : "",
                          "text-center"
                        )}
                      >
                        Pending
                      </div>
                      <div
                        className={classNames(
                          step > 2 ? "text-yellow-600" : "",
                          "text-center"
                        )}
                      >
                        Accepted
                      </div>
                      <div
                        className={classNames(
                          step > 3 ? "text-indigo-600" : "",
                          "text-right"
                        )}
                      >
                        Confirmed
                      </div>
                      <div
                        className={classNames(
                          step > 4 ? "text-green-600" : "",
                          "text-right"
                        )}
                      >
                        Paid
                      </div>
                    </div>
                  </div>
                </div>
                {documents?.orderService?.map((data) => (
                  <div
                    key={data.id}
                    className="bg-white border-t border-b border-gray-200 shadow-sm sm:border sm:rounded-lg"
                  >
                    <div className="py-6 px-4 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
                      <div className="sm:flex lg:col-span-7">
                        <div className="flex-shrink-0 w-full aspect-w-1 aspect-h-1 rounded-lg overflow-hidden sm:aspect-none sm:w-40 sm:h-40">
                          <img
                            src={data.serviceIcon}
                            alt={data.serviceTitle}
                            className="w-full h-full object-center object-cover sm:w-full sm:h-full"
                          />
                        </div>

                        <div className="mt-6 sm:mt-0 sm:ml-6">
                          <h3 className="text-base font-medium text-gray-900">
                            <a href={data.serviceTitle}>{data.serviceTitle}</a>
                          </h3>
                          <p className="mt-2 text-sm font-medium text-gray-900">
                            {data.servicePrice}-Birr
                          </p>
                          <p className="mt-3 text-sm text-gray-500">
                            {data.serviceDesc}
                          </p>
                        </div>
                      </div>

                      <div className="mt-6 lg:mt-0 lg:col-span-5">
                        <dl className="grid grid-cols-2 gap-x-6 text-sm">
                          <div>
                            <dt className="font-medium text-gray-900">
                              Service Type
                            </dt>
                            <dd className="mt-3 text-gray-500">
                              <span className="block">{data.serviceTitle}</span>
                            </dd>
                          </div>
                          <div>
                            <dt className="font-medium text-gray-900">Price</dt>
                            <dd className="mt-3 text-gray-500 space-y-3">
                              <p>
                                Quantity <strong>{data.qty}</strong>x
                              </p>
                              <p>
                                Price <strong>{data.servicePrice}</strong>
                              </p>
                              <p>
                                Total Price{" "}
                                <strong>{(data.servicePrice * data.qty) || '-'}</strong>
                              </p>
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Billing */}
            <div className="mt-16">
              <h2 className="sr-only">Billing Summary</h2>

              <div className="bg-gray-100 py-6 px-4 sm:px-6 sm:rounded-lg lg:px-8 lg:py-8 lg:grid lg:grid-cols-12 lg:gap-x-8">
                <dl className="grid grid-cols-2 gap-6 text-sm sm:grid-cols-2 md:gap-x-8 lg:col-span-7">
                  <div>
                    <dt className="font-medium text-gray-900">
                      Billing address
                    </dt>
                    <dd className="mt-3 text-gray-500">
                      <span className="block">
                        {documents.orderServiceLocation}
                      </span>
                    </dd>
                  </div>
                  <div>
                    <dt className="font-medium text-gray-900">Order Date</dt>
                    <dd className="mt-3 text-gray-500">
                      <span className="block">
                        {getTimeStamp(documents.orderDate)}
                      </span>
                    </dd>
                  </div>
                  <div>
                    <dt className="font-medium text-gray-900">
                      Payment information
                    </dt>
                    <div className="mt-3">
                      <dd className="-ml-4 -mt-4 flex flex-wrap">
                        <div className="ml-4 mt-4 flex-shrink-0">
                          <p className="">Bank Transfer</p>
                        </div>
                      </dd>
                    </div>
                  </div>
                </dl>

                <dl className="mt-8 divide-y divide-gray-200 text-sm lg:mt-0 lg:col-span-5">
                  <div className="pt-4 flex items-center justify-between">
                    <dt className="font-medium text-gray-900">Total Amount</dt>
                    <dd className="font-medium text-indigo-600">
                      {totalPrice || '-'}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
