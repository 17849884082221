import React from "react"
import { Route, Router, useLocation, useParams } from "react-router-dom"
import { CartContainer, Header } from "../components"
import TabsRender from "../components/Tabs"
import { useStateValue } from "../context/StateProvider"

function ProfileView(props) {
  const [{ user, cartShow, cartItems }, dispatch] = useStateValue()
  const location = useLocation();
  return (
    <>
      <Header sticky={true} />
      <div className="w-full h-auto flex flex-col items-center justify-center px-4 md:px-16 mt-24">
        <div className="py-6 bg-primary w-full">
          <div className="container">
            <div className="grid grid-cols-12 gap-x-4">
              <div className="col-span-12">
                <nav>
                  <ul className="flex flex-wrap items-center justify-center">
                    <li className="mr-5">
                      <a
                        href="/"
                        className="text-dark font-medium text-base uppercase transition-all hover:text-orange relative before:w-5 before:h-1px before:empty before:absolute before:top-3 before:bg-dark before:transform before:rotate-115 before:-right-5"
                      >
                        Home
                      </a>
                    </li>{" "}
                    /
                    <li className="text-dark font-medium text-base uppercase mr-5">
                      account Page
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <TabsRender indexValue={location.state} />

        {cartShow && <CartContainer />}
      </div>
    </>
  )
}

export default ProfileView
