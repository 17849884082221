import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { Header, MainContainer, Footer } from "./components";
import { useStateValue } from "./context/StateProvider";
import { getAllServices } from "./utils/firebaseFunctions";
import { actionType } from "./context/reducer";
import  About  from "./page/about/about";
import Order from "./page/profile";
import Listing from "./page/listing"
import Login from "./page/auth/Login";
import Service from "./page/service";
import Register from "./page/auth/Register";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import Request from "./components/request";
import OrderView from "./page/client/order";
import AssignedWorker from "./components/client/assigned";
import PrivateRoute from "./components/private/PrivateRoute";
import OrderDetail from "./components/client/order/index";
import Terms from "./page/terms/";
import Faqs from "./page/faqs/";
import ScrollToTop from "./components/scroll/ScrollToTop";
import { motion } from "framer-motion";


const App = () => {
  const [{ user }, dispatch] = useStateValue();
 
  const fetchData = async () => {
    await getAllServices('service').then((data) => {
      dispatch({
        type: actionType.SET_SERVICE_ITEMS,
        serviceItems: data,
      });
    });
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  return (
  <>
      <ScrollToTop/>
      <div className="w-screen h-auto flex flex-col">
        {/* <Header /> */}

        <main className="w-full">
          <Routes>
            <Route path="/*" element={<MainContainer />} />
            <Route path="/about" element={<About />} />
            <Route path="/service" element={<Service />} />

            <Route path='/profile' element={<PrivateRoute />}>
              <Route path='/profile' element={<Order />} />
            </Route>

            {/* <Route path="/profile" element={<Order />} /> */}
            <Route path="/login" element={<Login />} />

            <Route path='/request' element={<PrivateRoute />}>
              <Route path="/request" element={<Request />} />
            </Route>

            <Route
            path='/category/:categoryName/:serviceID'
            element={<Listing />}
          />
          
            <Route path="/register" element={<Register />} />

            <Route path='/order/:id' element={<PrivateRoute />}>
              <Route path="/order/:id" element={<OrderView />} />
            </Route>
           
            <Route path='/order/view/:id' element={<PrivateRoute />}>
              <Route path="/order/view/:id" element={<OrderDetail />} />
            </Route>
            <Route path='/assigned/:id' element={<PrivateRoute />}>
              <Route path="/assigned/:id" element={<AssignedWorker />} />
            </Route>
            <Route path="/terms" element={<Terms />} />
            <Route path="/faqs" element={<Faqs />}/>
          </Routes>
        </main>
        <Footer/>
      </div>
      <AnimatePresence exitBeforeEnter>
        <motion.div key="toast-container">
          <ToastContainer />
        </motion.div>
      </AnimatePresence>
  </>
  );
};

export default App;
