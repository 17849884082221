import React, { useEffect, useState } from "react"
import { useCollection } from "../utils/hooks/useCollection"
import { motion } from "framer-motion"
import RowContainer from "./RowContainer"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/pagination"
import "swiper/css/navigation"
// import required modules
import { FreeMode, Pagination, Navigation } from "swiper"

const MenuContainer = () => {
  const [filter, setFilter] = useState("Chef")
  const { isPending, error, documents } = useCollection("service_type",["srvStatus","==","Active"],["srvTypeOrder","asc"])
  const service = useCollection("service",["serviceStatus","==","Active"],["serviceOrder","asc"])


  return (
    <section className="my-6 container mx-auto" id="menu">
      <div className="container mx-auto px-6 lg:px-56">
        <h1 className="text-4xl font-extrabold text-center my-5">
        Choose Service
        </h1>
        <Swiper
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 5,
            },
            380: {
              slidesPerView: 1,
              spaceBetween: 5,
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 5,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 5,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 5,
            },
          }}
          slidesPerView={4}
          spaceBetween={5}
          freeMode={true}
          mousewheel={true}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode, Pagination, Navigation]}
          className="mySwiper"
        >
          {documents &&
            documents.map((category, index) => (
              <SwiperSlide key={index}>
                <motion.div whileHover={{ scale: 0.95 }} key={category.id}>
                  <div
                    className={`relative group  hover:cursor-grab rounded-md h-full ${
                      category.srvTypeTitle === filter ? "bg-categoryBgActive" : "bg-categoryBg"
                    }`} key={category.id}>
                    <div
                      className="h-100"
                      onClick={() => setFilter(category.srvTypeTitle)}
                    >
                      <img
                        src={category.srvTypeImg}
                        className="w-full rounded-md bg-cover p-4 pb-10"
                        alt=""
                      />
                      <div className={`absolute bottom-0 flex  w-full flex-col items-center justify-center bg-gradient-to-r px-3 py-2 group-hover:hidden ${
                      category.srvTypeTitle === filter ? "from-orange-500" : "from-green-500"
                    }`}>
                        <h1 className="text-white text-1xl text-center">{category.srvTypeTitle}</h1>
                      </div>
                      <div className="absolute top-0 flex h-full w-full flex-col items-center justify-center bg-transparent transition duration-500 group-hover:bg-black group-hover:bg-opacity-70">
                        <h3 className="font-anton text-xs uppercase text-transparent transition duration-500 group-hover:text-white">
                          {category.srvTypeTitle}
                        </h3>
                        <h5 className="font-montserrat font-bold text-transparent transition duration-500 group-hover:text-white">
                          {category.srvTypeTitle}
                        </h5>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
      <div className="container mx-auto">
        <div className="flex items-center justify-center  px-4 md:px-16 ">
          <RowContainer
            flag={false}
            size={8}
            data={service?.documents?.filter((n) => n.serviceType === filter)}
          />
        </div>
      </div>
    </section>
  )
}

export default MenuContainer
