import React from 'react'
import { Header, MenuContainer } from '../../components'

function Service() {
  return (
   <>
     <Header sticky={true}/>
     <div className="mt-24"></div>
     <MenuContainer />
     
   </>
  )
}

export default Service