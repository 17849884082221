import { getApp, getApps, initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { initializeAppCheck, ReCaptchaV3Provider }  from "firebase/app-check";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAaYIgYqWxLF8u4zJySF7lWqPY0Equgw-s",
  authDomain: "wiatershrmdb.firebaseapp.com",
  projectId: "wiatershrmdb",
  storageBucket: "wiatershrmdb.appspot.com",
  messagingSenderId: "721349905578",
  appId: "1:721349905578:web:15077d2378ca12e83994d3"
};


const app = getApps.length > 0 ? getApp() : initializeApp(firebaseConfig);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LdB7GYkAAAAAJj3MfI6AfTlZ87bjmDdVdZJSUlM'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});
const db = getFirestore(app);
const storage = getStorage(app);

export { app, db, storage, appCheck };




