import { async } from "@firebase/util";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase.config";

// Saving new Item
export const saveItem = async (data) => {
  await setDoc(doc(db, "request", `${Date.now()}`), data, {
    merge: true,
  });
};

// get all service

export const getAllServices = async (service) => {
  const collectionRef = collection(db, service);
  const q = query(collectionRef,where("serviceStatus","==",'Active'));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((docSnapshot) => ({...docSnapshot.data(),id:docSnapshot.id}));
}
