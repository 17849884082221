import React, { useEffect, useRef, useState } from "react"

import Logo from "../assets/img/logo/WaiterApp_Logo.png"
import Avatar from "../img/avatar.png"
import { Link, useNavigate } from "react-router-dom"
import { useStateValue } from "../context/StateProvider"
import { actionType } from "../context/reducer"
import { fetchCart } from "../utils/fetchLocalStorageData"
import CartContainer from "./CartContainer"
import { getAuth } from "firebase/auth"
import { Fragment } from "react"
import { Menu, Popover, Transition } from "@headlessui/react"
import { useAuthStatus } from "../utils/hooks/useAuth"
import { BiCartAlt, BiMenu, BiX } from "react-icons/bi"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}
const navigation = [
  { name: "Home", href: "/" },
  { name: "About", href: "/about" },
  { name: "Service", href: "/service" },
]

const Header = ({ sticky }) => {
  const [isScrolling, setIsScrolling] = useState(sticky)
  const [height, setHeight] = useState(0)
  const ref = useRef()

  useEffect(() => {
    setHeight(ref.current.clientHeight)
  }, [])

  useEffect(() => {
    window.onscroll = () => {
      if (sticky) {
        window.scrollY === 0 && setIsScrolling(true)
      } else {
        window.scrollY === 0 && setIsScrolling(false)
        window.scrollY > height && setIsScrolling(true)
      }
    }
  }, [height])
  const [{ user, cartShow, cartItems }, dispatch] = useStateValue()
  const [open, setOpen] = useState(false)
  const auth = getAuth()

  const navigate = useNavigate()
  const cartItemsData = fetchCart()

  const logout = () => {
    localStorage.clear()
    dispatch({
      type: actionType.SET_USER,
      user: null,
    })
    auth.signOut()
    navigate("/")
  }

  const showCart = () => {
    setOpen(!open)
  }

  return (
    <>
      <Popover as="header" className="fixed z-50 w-screen pb-24">
        <div
          ref={ref}
          className={`fixed py-6 z-20 w-full transition-all duration-500 ${
            isScrolling ? "bg-white shadow-md" : "bg-transparent"
          }`}
        >
          <nav
            className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
            aria-label="Global"
          >
            <div className="flex items-center flex-1">
              <div className="flex items-center justify-between w-full">
                <Link to="/">
                  <img className="h-12 w-full" src={Logo} alt="" />
                </Link>
                <div className="-mr-2 flex items-center lg:hidden">
                  <Popover.Button className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                    <BiMenu className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="hidden space-x-8 lg:flex lg:ml-96">
                {navigation.map((item, index) => (
                  <Link
                    key={index}
                    to={item.href}
                    className={`text-base font-medium hover:text-primary ${
                      isScrolling ? "text-black" : "text-white"
                    }`}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
            <div className="hidden lg:flex lg:items-center lg:space-x-6">
              {(user?.userData?.role === "client" || user === null) && (
                <div
                  className="relative flex items-center justify-center"
                  onClick={showCart}
                >
                  <BiCartAlt className="text-orange-500 text-3xl  cursor-pointer" />
                  {cartItemsData && cartItemsData.length > 0 && (
                    <div className=" absolute -top-2 -right-2 w-5 h-5 rounded-full bg-cartNumBg flex items-center justify-center">
                      <p className="text-xs text-white font-semibold">
                        {cartItemsData.length}
                      </p>
                    </div>
                  )}
                </div>
              )}
              {!user && (
                <>
                  <Link to="/login">
                    <button className="mx-2 my-2 bg-secondary transition duration-150 ease-in-out hover:bg-primary rounded text-white px-6 py-2 text-xs">
                      Sign In
                    </button>
                  </Link>

                  <Link to="/register">
                    <button className="mx-2 my-2 bg-secondary transition duration-150 ease-in-out hover:bg-primary rounded text-white px-6 py-2 text-xs">
                      Sign Up
                    </button>
                  </Link>
                </>
              )}

              {user && (
                <Menu as="div" className="ml-4 relative flex-shrink-0">
                  <div>
                    <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={Avatar}
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/profile"
                            state='1'
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                            )}
                          >
                           My  Orders
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/profile"
                            state='2'
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                            )}
                          >
                           My Profile
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <p
                            onClick={logout}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                            )}
                          >
                            Sign out
                          </p>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              )}
            </div>
          </nav>
        </div>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute top-0 inset-x-0 p-2 transition transform origin-top lg:hidden z-50"
          >
            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div className="flex justify-between gap-9">
                  <img className="h-8 w-auto" src={Logo} alt="" />
                  {(user?.userData?.role === "client" || user === null) && (
                    <div
                      className="relative flex items-center justify-center"
                      onClick={showCart}
                    >
                      <BiCartAlt className="text-orange-500 text-3xl  cursor-pointer" />
                      {cartItemsData && cartItemsData.length > 0 && (
                        <div className=" absolute -top-2 -right-2 w-5 h-5 rounded-full bg-cartNumBg flex items-center justify-center">
                          <p className="text-xs text-white font-semibold">
                            {cartItemsData.length}
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600">
                    <span className="sr-only">Close menu</span>
                    <BiX className="h-8 w-8 text-red-600" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="pt-5 pb-6">
                <div className="px-2 space-y-1 flex justify-center items-center flex-col">
                  {navigation.map((item, index) => (
                    <Link
                      key={index}
                      to={item.href}
                      className="px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </Link>
                  ))}
                  {user && (
                    <>
                      <Link
                        to="/profile"
                        state="2"
                        className="px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50"
                      >
                        My Profile
                      </Link>
                      <Link
                        to="/profile"
                        state="1"
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50"
                      >
                        My Orders
                      </Link>
                      <p
                        onClick={logout}
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50"
                      >
                        Sign out
                      </p>
                    </>
                  )}
                </div>

                {!user && (
                  <>
                    <div className="mt-6 px-5">
                      <Link
                        to="/register"
                        className="block text-center w-full py-3 px-4 rounded-md shadow bg-gradient-to-r from-secondary to-primary text-white font-medium hover:from-teal-600 hover:to-cyan-700"
                      >
                        Register
                      </Link>
                    </div>
                    <div className="mt-6 px-5">
                      <p className="text-center text-base font-medium text-gray-500">
                        Already Have an Account!{" "}
                        <Link
                          to="/login"
                          className="text-gray-900 hover:underline"
                        >
                          Login
                        </Link>
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
      <CartContainer show={open} handleChange={showCart} />
    </>
  )
}

export default Header
