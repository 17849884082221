import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useStateValue } from "../../context/StateProvider"
import { useDocument } from "../../utils/hooks/useDocument"
import { toast } from "react-toastify"
import { fetchCart } from "../../utils/fetchLocalStorageData"
import { actionType } from "../../context/reducer"
import Header from "../../components/Header"
import Spinner from "../../components/spinner/spinner"

function Detail() {
  const params = useParams()
  const { isPending, error, documents } = useDocument(
    "service",
    params?.serviceID
  )
  const [items, setItems] = useState([])
  const [{ cartItems, user }, dispatch] = useStateValue()

  const addtocart = () => {
    let cartData = fetchCart()

    let current = ""
    if (items.length > 0) {
      current = items
    } else {
      current = cartData
    }
    dispatch({
      type: actionType.SET_CARTITEMS,
      cartItems: current,
    })
    localStorage.setItem("cartItems", JSON.stringify(current))
  }

  useEffect(() => {
    addtocart()
  }, [items])

  const addCartItem = (cartItems, serviceToAdd) => {
    serviceToAdd.qty = 1
    const existingCartItem = cartItems.find(
      (cartItem) => cartItem.id === serviceToAdd.id
    )
    if (user?.userData.role === "worker") {
      toast.error("you can't add cart", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })

      return
    }
    if (existingCartItem) {
      toast.error("cart already existed!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      return cartItems.map((cartItem) =>
        cartItem.id === serviceToAdd.id
          ? { ...cartItem, quantity: cartItem.quantity + 1 }
          : cartItem
      )
    } else {
        // Remove unused Field
      const newServiceToAdd = serviceToAdd
      delete newServiceToAdd.serviceStatus
      delete newServiceToAdd.createdAt
      setItems([...cartItems, serviceToAdd])
      toast("Cart Add Successfully!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }

    return [...cartItems, { ...serviceToAdd, quantity: 1 }]
  }
    
  return (
    <>
    <Header sticky={true}/>
    {!documents && <div className="mt-56"><Spinner w={100} h={100}/></div>}
    <div className="bg-white mt-10 md:mt-2">
      <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-x-8">
        {/* Service Details  */}
        {documents && (
          <>
            <div className="lg:max-w-lg lg:self-end">
              <nav aria-label="Breadcrumb">
                <ol className="flex items-center space-x-2">
                  <li>
                    <div className="flex items-center text-sm">
                      <Link
                        to={""}
                        className="font-medium text-primary hover:text-orange-500"
                      >
                        {documents.serviceType}
                      </Link>
                      <svg
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        aria-hidden="true"
                        className="ml-2 flex-shrink-0 h-5 w-5 text-gray-300"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center text-sm">
                      <p className="font-medium text-gray-500 hover:text-gray-900 cursor-pointer">
                        {documents.serviceTitle}
                      </p>
                    </div>
                  </li>
                </ol>
              </nav>

              <div className="mt-4">
                <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  {documents.serviceTitle}
                </h1>
              </div>

              <section aria-labelledby="information-heading" className="mt-4">
                <h2 id="information-heading" className="sr-only">
                  Service information
                </h2>

                <div className="flex items-center">
                  <p className="text-lg text-gray-900 sm:text-xl">
                    {documents.servicePrice}
                  </p>
                </div>

                <div className="mt-4 space-y-6">
                  <p className="text-base text-gray-500">
                    {documents.serviceDesc}
                  </p>
                </div>
              </section>
            </div>

            {/* Service image */}
            <div className="mt-10 lg:mt-0 lg:col-start-2 lg:row-span-2 lg:self-center">
              <div className="aspect-w-1 aspect-h-1 rounded-lg overflow-hidden bg-slate-300">
                <img
                  src={documents.serviceIcon}
                  alt={documents.serviceTitle}
                  className="w-full h-full object-center object-cover"
                />
              </div>
            </div>

            <div className="mt-10 lg:max-w-lg lg:col-start-1 lg:row-start-2 lg:self-start">
              <section aria-labelledby="options-heading">
                <div className="sm:flex sm:justify-between"></div>
                <div className="mt-10">
                  <button
                    type="button"
                    className="w-full bg-primary border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-orange-500"
                    onClick={() => addCartItem(cartItems, documents)}
                  >
                    Add to cart
                  </button>
                </div>
              </section>
            </div>
          </>
        )}
      </div>
    </div>
    </>
  )
}

export default Detail
