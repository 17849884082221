import React, { useEffect, useState } from "react"
import { BiMinus, BiPlus } from "react-icons/bi"
import { motion } from "framer-motion"
import { useStateValue } from "../context/StateProvider"
import { actionType } from "../context/reducer"
import { fetchCart } from "../utils/fetchLocalStorageData"
let items = []

const CartItem = ({ item, setFlag, flag }) => {
  const [{ cartItems }, dispatch] = useStateValue()
  const [qty, setQty] = useState(item.qty)
  console.log(qty)
  const currentItems = fetchCart()
  const cartDispatch = () => {
    let current = ""
    if (items.length > 0) {
      current = items
    } else {
      current = currentItems
    }
    localStorage.setItem("cartItems", JSON.stringify(current))
    dispatch({
      type: actionType.SET_CARTITEMS,
      cartItems: current,
    })
  }

  const updateQty = (action, id) => {
    if (action === "add") {
      setQty(qty + 1)
      console.log("id", qty)
      cartItems.map((item) => {
        if (item.id === id) {
          item.qty += 1
          setFlag(flag + 1)
        }
      })
      cartDispatch()
    } else {
      // initial state value is one so  if 1 then remove it
      if (qty === 1) {
        items = cartItems.filter((item) => item.id !== id)
        setFlag(flag + 1)
        cartDispatch()
      } else {
        setQty(qty - 1)
        cartItems.map((item) => {
          if (item.id === id) {
            item.qty -= 1
            setFlag(flag + 1)
          }
        })
        cartDispatch()
      }
    }
  }
  const removeCartItem = (cartItemsOne, cartItemToRemove) => {
    console.log(cartItemToRemove, cartItemsOne)
    // find the cart item to remove
    const existingCartItem = cartItems.filter(
      (item) => item.id === cartItemToRemove
    )

    // check if quantity is equal to 1, if it is remove that item from the cart
    if (existingCartItem) {
      let cartItemsUpdate = currentItems.filter(
        (cartItem) => cartItem.id !== cartItemToRemove
      )
      console.log("update", cartItemsUpdate)
      const cartDispatch = () => {
        dispatch({
          type: actionType.SET_CARTITEMS,
          cartItems: cartItemsUpdate,
        })
      }
      cartDispatch()
      localStorage.setItem("cartItems", JSON.stringify(cartItemsUpdate))
    }

    // return back cartitemt with matching cart item with reduced quantity
    return cartItems.map((cartItem) =>
      cartItem.id === cartItemToRemove
        ? { ...cartItem, quantity: cartItem.quantity - 1 }
        : cartItem
    )
  }

  useEffect(() => {
    items = cartItems
  }, [qty, cartItems])

  return (
    <>
      <li key={item?.id} className="flex py-6">
        <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
          <img
            src={item?.serviceIcon}
            alt={item?.serviceTitle}
            className="h-full w-full object-cover object-center"
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between text-base font-medium text-gray-900">
              <h3>
                <div>{item?.serviceTitle}</div>
              </h3>
            </div>
            <p className="mt-1 text-sm text-gray-500">{item?.type}</p>
          </div>
          <div className="group flex items-center gap-2 ml-auto cursor-pointer">
            <motion.div
              whileTap={{ scale: 0.75 }}
              onClick={() => updateQty("remove", item?.id)}
            >
              <BiMinus className="text-black" />
            </motion.div>

            <p className="w-5 h-5 rounded-sm bg-cartBg text-gray-50 flex items-center justify-center">
              {qty}
            </p>

            <motion.div
              whileTap={{ scale: 0.75 }}
              onClick={() => updateQty("add", item?.id)}
            >
              <BiPlus className="text-black " />
            </motion.div>
            <p
              className="text-black"
              onClick={() => removeCartItem(item, item?.id)}
            >
              remove
            </p>
          </div>
        </div>
      </li>
    </>
  )
}

export default CartItem
