import React, { useState, useEffect } from 'react'
import Moment from 'react-moment';
import { useStateValue } from '../../context/StateProvider';
import { useCollection } from '../../utils/hooks/useCollection';
import { getTimeStampDate } from '../../utils/utils';
import DataTable from "react-data-table-component"

const statusObj = {
  Served: "bg-primary",
  Confirmed: "bg-secondary",
  Pending: "bg-orange-300",
  Success: "bg-green-300",
  Rejected: "bg-red-500",
  Paid: "bg-green-500",
  Rated: "bg-green-800",
}

export const columns = [
  {
    name: "Name",
    sortable: true,
    minWidth: "200px",
    sortField: "Name",
    selector: (row) => row.companyName,
    cell: (row) => (
        <span className="text-base font-medium leading-4 text-gray-600 cursor-pointer">
          {row.companyName || "Individual"}
        </span>
    ),
  },
  {
    name: "Service Time",
    sortable: true,
    selector: (row) => row.orderServiceTime,
    cell: (row) => <span>{row.orderServiceTime}</span>,
  },
  {
    name: "When",
    sortable: true,
    selector: (row) => row.orderServiceDate,
    cell: (row) => (
      <div className="text-capitalize">
        <span>{row.orderServiceDate}</span>
      </div>
    ),
  },
  {
    name: "Order Date",
    sortable: true,
    selector: (row) => row.orderDate,
    cell: (row) => (
      <div className="text-capitalize">
        <Moment fromNow>{getTimeStampDate(row.orderDate)}</Moment>
      </div>
    ),
  },
  {
    name: "Status",
    sortable: true,
    selector: (row) => row.orderStatus,
    cell: (row) => (
      <div
        className={`text-base font-medium leading-4 p-2 border-collapse  ${
          statusObj[row.orderStatus]
        }`}
      >
        {row.orderStatus}
      </div>
    ),
  },
]

function ListRequest() {
  const { isPending, error, documents } = useCollection('orders')
  const [data, setData] = useState()
  const [{ user}, dispatch] = useStateValue()
  const assigned = []

  useEffect(() => {
    return () => {
      // cancel any outstanding tasks or subscriptions here
    }
  }, [])

  if(isPending){
    return <p>loading...</p>
  }

  documents?.map((document) => {
    if(document?.assign?.filter((data) => data.value === user.userData?.id).length > 0) {
      assigned.push(document)
    }
    return(
      <></>
    )
  })

  return (
    <div>
      {assigned && (
        <DataTable
          columns={columns}
          data={assigned}
          progressPending={isPending}
          pagination
        />
      )}
    </div>
  )
}

export default ListRequest
