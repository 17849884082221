import { TailSpin } from "react-loader-spinner"

function Spinner({w,h}) {
    return (
        <div className="flex items-center justify-center">
            <TailSpin
            height={h}
            width={w}
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
        />
      </div>
    )
  }
  
  export default Spinner