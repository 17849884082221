import React, { useState } from "react"
import { actionType } from "../context/reducer"
import { useStateValue } from "../context/StateProvider"
import CheckOut from "./request"
import Order from "./client/Order"
// import Request from "./profile/worker/Request"
import { RiGhostLine } from "react-icons/ri"
import { FaFilePdf } from "react-icons/fa"
import ListRequest from "./worker/Request"
import { Tab } from "@headlessui/react"
import WorkerProfile from "./worker/profile"
import ClientProfile from "./client/profile"
import { useEffect } from "react"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const Tabs = ({indexValue}) => {
  const [{ user }] = useStateValue()
  const [selectedIndex, setSelectedIndex] = useState(0)
  useEffect(()=>{
    setSelectedIndex(indexValue)
  },[indexValue])
  const renderDashboard = () => {
    return (
      <>
        <div className="p-8 border border-gray-200">
          <h3 className="font-semibold text-md lg:text-lg capitalize pb-5 mb-5 border-b border-gray-600 leading-none">
            {user && user.userData?.role} Dashboard
          </h3>
          <p>
            Hello, <strong>{user && user.userData?.displayName}</strong>
            <span className="transition-all hover:cursor-pointer hover:text-orange text-red-500 ml-1">
              Logout
            </span>
          </p>
          <p>
            From your account dashboard. you can easily check &amp; view your
            recent orders, edit your password and account details.
          </p>
        </div>
      </>
    )
  }
  const renderOrder = () => {
    return (
      <>
        {user.userData?.role === "worker" && <ListRequest />}
        {user.userData?.role === "client" && <Order />}
      </>
    )
  }
  const renderProfile =() => {
    return (
      <>
       {user.userData?.role === "worker" && <WorkerProfile/>}
       {user.userData?.role === "client" && <ClientProfile />}
      </>
    )
  }
  let [tabList] = useState({
    Dashboard: renderDashboard(),
    Orders: renderOrder(),
    Profile: renderProfile(),
  })

  return (
    <>
      <div className="w-full px-2 py-16 sm:px-0">
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <Tab.List className="w-full max-w-md flex space-x-1 rounded-xl bg-blue-900/20 p-1 align-middle">
            {Object.keys(tabList).map((category) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  classNames(
                    "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                    "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                    selected
                      ? "bg-white shadow"
                      : "text-orange-500 hover:bg-white/[0.12] hover:text-white"
                  )
                }
              >
                {category}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="mt-2 w-full">
            {Object.values(tabList).map((item, idx) => (
              <Tab.Panel
                key={idx}
                className={classNames(
                  "rounded-xl bg-white p-3",
                  "ring-white ring-opacity-60 ring-offset-2"
                )}
              >
                <ul>{item}</ul>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  )
}

export default Tabs
