import { getStorage, ref } from "firebase/storage"
import React, { Fragment, useEffect, useRef, useState } from "react"
import { FaCheckCircle, FaFilePdf, FaStar } from "react-icons/fa"
import { useDocument } from "../../../utils/hooks/useDocument"
import Avatar from "../../../assets/img/avatar/avatar.png"
import { Dialog, Transition } from "@headlessui/react"
import { BiStar } from "react-icons/bi"
import { useFirestore } from "../../../utils/hooks/useFirebase"
import { useCollection } from "../../../utils/hooks/useCollection"

const colors = {
  orange: "#FFBA5A",
  grey: "#a9a9a9",
}
function AssignedUser({ id, orderID,clientID }) {
  // Fetch Worker Detail
  const { isPending, error, documents } = useDocument("worker", id)
  const [rateStar, setRateStar] = useState(0)

  const { addDocument } = useFirestore("rating")
  const rateData = useCollection("rating", ["workerID", "==", id])
  const rating = useCollection("rating")
  // Modal Controller
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)

  // Rating Controller
  const [currentValue, setCurrentValue] = useState(1)
  const [rateDesc, setDesc] = useState("")
  const [hoverValue, setHoverValue] = useState(undefined)
  const stars = Array(5).fill(2)

  const handleClick = (value) => {
    setCurrentValue(value)
  }
  const handleChange = (e) => {
    if(e.target.value.length < 300){
      setDesc(e.target.value)
    }
  }

  const handleMouseOver = (newHoverValue) => {
    setHoverValue(newHoverValue)
  }

  const handleMouseLeave = () => {
    setHoverValue(undefined)
  }
  const handleRate = async () => {
    const formData = {
      workerID: id,
      clientID: clientID,
      orderID,
      rateNum: currentValue,
      rateDesc,
    }
    await addDocument(formData)
  }
  const index = rating.documents?.findIndex(
    (data) => data.workerID === id && data.orderID === orderID
  )
  // Rating Star Calculation
  const oneStar = rateData?.documents?.filter((r) => r.rateNum === 1).length
  const twoStar = rateData?.documents?.filter((r) => r.rateNum === 2).length
  const threeStar = rateData?.documents?.filter((r) => r.rateNum === 3).length
  const fourStar = rateData?.documents?.filter((r) => r.rateNum === 4).length
  const fiveStar = rateData?.documents?.filter((r) => r.rateNum === 5).length

  useEffect(() => {
    const oneTotal = oneStar * 1
    const twoTotal = twoStar * 2
    const threeTotal = threeStar * 3
    const fourTotal = fourStar * 4
    const fiveTotal = fiveStar * 5

    const totalClicks = oneStar + twoStar + threeStar + fourStar + fiveStar
    const totalStars = oneTotal + twoTotal + threeTotal + fourTotal + fiveTotal
    const avgStars = totalStars / totalClicks

    setRateStar(avgStars.toPrecision(3))
  }, [rateData.documents])

  const renderStar = () => {
    const num = rating?.documents[index]?.rateNum
    const rateStar = Array(5).fill(5)
    return (
      <div className="flex flex-row my-2">
        {" "}
        {rateStar.map((_, index) => {
          return (
            <FaStar
              key={index}
              size={24}
              color={(hoverValue || num) > index ? colors.orange : colors.grey}
              style={{
                marginRight: 10,
                cursor: "pointer",
              }}
            />
          )
        })}
      </div>
    )
  }
  const renderCloseModalButton = () => {
    return (
      <div className="flex justify-end">
        <div
          onClick={() => setOpen(false)}
          className="text-right bg-red-500 p-2 rounded-md text-white cursor-pointer hover:bg-red-600"
        >
          Close
        </div>
      </div>
    )
  }
  // Render Rating Interface
  const renderContent = () => {
    return (
      <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <BiStar className="h-6 w-6 text-primary" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <div className="flex justify-between flex-row-reverse">
              {rateData?.documents?.length > 0 && (
                <>
                  {" "}
                  <p className="flex gap-1">
                    {" "}
                    {rateStar || 0}
                    <BiStar color="orange" />
                  </p>
                  <p>{rateData?.documents?.length || 0} reviews</p>
                </>
              )}
              <Dialog.Title
                as="h3"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                Review <br />{" "}
                <span className="font-large border-b-2 border-b-primary">
                  {" "}
                  {`${documents?.workerSName || ""} ${" "} ${
                    documents?.workerLName || ""
                  } ${documents?.workerFName || ""}`}
                </span>
              </Dialog.Title>
            </div>
            <div className="flex flex-row my-2">
              {index === -1 && (
                <div className="flex flex-row">
                  {stars.map((_, index) => {
                    return (
                      <FaStar
                        key={index}
                        size={24}
                        onClick={() => handleClick(index + 1)}
                        onMouseOver={() => handleMouseOver(index + 1)}
                        onMouseLeave={handleMouseLeave}
                        color={
                          (hoverValue || currentValue) > index
                            ? colors.orange
                            : colors.grey
                        }
                        style={{
                          marginRight: 10,
                          cursor: "pointer",
                        }}
                      />
                    )
                  })}
                </div>
              )}
            </div>
            <div className="w-full md:w-96">
              <label className="my-3 inline-block">Write Something </label>
              {index === -1 && (
                <>
                  <textarea
                    className="border border-solid border-gray-300 w-full py-1 px-5 mb-5 placeholder-current text-dark h-36 focus:outline-none text-base"
                    placeholder="Write Something about worker "
                    name="message"
                    type="textarea"
                    id="orderMessage"
                    maxLength={300}
                    onChange={handleChange}
                  ></textarea>
                  {renderCloseModalButton()}
                </>
              )}
            </div>
            {index >= 0 && (
              <>
                {renderStar()}
                <textarea
                  className="border border-solid border-gray-300 w-full py-1 px-5 mb-5 placeholder-current text-dark h-36"
                  disabled
                  name="message"
                  type="textarea"
                  id="orderMessage"
                  value={rating?.documents[index]?.rateDesc || ""}
                ></textarea>
                {renderCloseModalButton()}
              </>
            )}
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
          {index === -1 && (
            <button
              type="button"
              className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primaryLight focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:w-auto sm:text-sm"
              onClick={() => handleRate()}
            >
              Give Review
            </button>
          )}
        </div>
      </div>
    )
  }
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {renderContent()}
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="max-w-2xl mx-auto  sm:py-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="bg-white border-t border-b border-gray-200 shadow-sm sm:border sm:rounded-lg">
          <div className="py-6 px-4 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
            <div className="sm:flex lg:col-span-4">
              <div className="flex-shrink-0 w-full aspect-w-1 aspect-h-1 rounded-lg overflow-hidden sm:aspect-none sm:w-40 sm:h-40">
                {documents?.workerImg?.length > 0 ? (
                  <img
                    src={documents?.workerImg}
                    alt={documents?.workerSName}
                    className="w-full h-full object-center object-cover sm:w-full sm:h-full"
                  />
                ) : (
                  <img
                    src={Avatar}
                    alt={Avatar}
                    className="w-full h-full object-center object-cover sm:w-full sm:h-full"
                  />
                )}
              </div>

              <div className="mt-6 sm:mt-0 sm:ml-6">
                <h3 className="text-base font-medium text-gray-900">
                  <a href="data.serviceTitle">Name</a>
                </h3>
                <p className="mt-3 text-sm text-gray-500">{`${
                  documents?.workerSName || ""
                } ${documents?.workerLName || ""} ${
                  documents?.workerFName || ""
                }`}</p>
                <div
                  className="p-1 bg-primaryLight text-white text-center text-sm cursor-pointer"
                  onClick={(e) => setOpen(true)}
                >
                  Give Rate
                </div>
              </div>
            </div>

            <div className="mt-6 lg:mt-0 lg:col-span-8">
              <dl className="grid md:grid-cols-2 gap-x-6 text-sm">
                <div>
                  <dt className="font-medium text-gray-900">Information</dt>
                  <dd className="mt-3 text-gray-500 space-y-3">
                    <p>
                      Position <strong>{documents?.workerPosition || '-'}</strong>
                    </p>
                    <p>
                      Nationality{" "}
                      <strong>{documents?.workerNationality || '-'}</strong>
                    </p>
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Attachments
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                      {documents?.workerAttaches?.length > 0
                        ? ""
                        : "No Attachment"}
                      {documents &&
                        documents?.workerAttaches?.map((file, index) => {
                          const storage = getStorage()
                          const fileName = ref(storage, file)
                          var strArray = fileName.name.split(".")
                          return (
                            <li
                              className="pl-3 pr-4 py-3 flex items-center justify-between text-sm cursor-pointer"
                              key={index}
                            >
                              <div className="w-0 flex-1 flex items-center">
                                <FaFilePdf
                                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span className="ml-2 flex-1 w-0 truncate">
                                  {strArray[0]}
                                </span>
                              </div>
                              <div className="ml-4 flex-shrink-0">
                                <a
                                  href={file}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="font-medium text-indigo-600 hover:text-indigo-500"
                                >
                                  Download
                                </a>
                              </div>
                            </li>
                          )
                        })}
                    </ul>
                  </dd>
                </div>
              </dl>
              <div className="bg-white px-4 py-5 grid grid-cols-1 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Skills</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1 grid grid-cols-1 md:grid-cols-3 gap-1">
                  {documents?.workerSkills?.length > 0 ? "" : "No Skill"}
                  {documents &&
                    documents?.workerSkills?.map((skill, index) => {
                      return (
                        <li
                          className="pl-3 pr-4 py-3 flex items-center justify-between text-sm cursor-pointer"
                          key={index}
                        >
                          <div className="w-0 flex-1 flex items-center">
                            <FaCheckCircle
                              className="flex-shrink-0 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            <span className="ml-2 flex-1 w-fit">
                              {skill.value}
                            </span>
                          </div>
                        </li>
                      )
                    })}
                </dd>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AssignedUser
