import React from "react"
import DataTable from "react-data-table-component"
import { fetchUser } from "../../utils/fetchLocalStorageData"
import { useCollection } from "../../utils/hooks/useCollection"
import { Link } from "react-router-dom"
import Spinner from "../spinner/spinner"
import OrderStat from "./order/OrderStat"
import { getTimeStampDate, getDateTimeString } from "../../utils/utils"
import Moment from "react-moment"
import moment from "moment"

const statusObj = {
  Served: "bg-primary",
  Confirmed: "bg-secondary",
  Pending: "bg-orange-300",
  Success: "bg-green-300",
  Rejected: "bg-red-500",
  Paid: "bg-green-500",
  Accepted: "bg-green-500",
  Rated: "bg-green-600",
}
const time = (date) => {
  if(date){
    const type = "YYYY-MM-DD"
    console.log(moment(date).format(type))
    return moment(date).format(type)
  }
}

export const columns = [
  {
    name: "Name",
    sortable: true,
    minWidth: "200px",
    sortField: "Name",
    selector: (row) => row.companyName,
    cell: (row) => (
      <Link to={`/order/view/${row.id}`}>
        <span className="text-base font-medium leading-4 text-gray-600 cursor-pointer">
          {row.companyName === "Individual" ? row.orderUser.userFName : row.companyName || row.orderUser.userFName}
        </span>
      </Link>
    ),
  },
  {
    name: "Service Time",
    sortable: true,
    selector: (row) => row.orderServiceTime,
    cell: (row) => <span>{row.orderServiceTime}</span>,
  },
  {
    name: "When",
    sortable: true,
    selector: (row) => row.orderServiceDate,
    cell: (row) => (
      <div className="text-capitalize">
       {getDateTimeString(row.orderStartDate)} - {getDateTimeString(row.orderEndDate)}
      </div>
    ),
  },
  {
    name: "Order Date",
    sortable: true,
    selector: (row) => row.orderDate,
    cell: (row) => (
      <div className="text-capitalize">
        <Moment fromNow>{getTimeStampDate(row.orderDate)}</Moment>
      </div>
    ),
  },

  {
    name: "Status",
    sortable: true,
    selector: (row) => row.orderStatus,
    cell: (row) => (
      <div
        className={`text-base font-medium leading-4 p-2 border-collapse  ${
          statusObj[row.orderStatus]
        }`}
      >
        {row.orderStatus}
      </div>
    ),
  },
]
const userData = fetchUser()

function Order() {
  const { isPending, error, documents } = useCollection(
    "orders",
    ["orderUserId", "==", userData.userData?.id],
    ["orderDate", "desc"]
    )
  if (error) {
    console.log(error)
  }
  // Client Order List
  return (
    <>
      <OrderStat />
      {isPending && <Spinner />}
      {documents && (
        <DataTable
          columns={columns}
          data={documents}
          progressPending={isPending}
          pagination
          theme="default"
        />
      )}
    </>
  )
}

export default Order
