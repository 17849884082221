import { addDoc, collection, serverTimestamp } from "firebase/firestore"
import React, { useEffect, useState } from "react"
import Select from "react-select"
import { db } from "../../firebase.config"
import { fetchCart, fetchUser } from "../../utils/fetchLocalStorageData"
import { getPreferableLanRef, getServiceTimeRef } from "../../utils/utils"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import CartContainer from "../CartContainer"
import { useStateValue } from "../../context/StateProvider"
import Header from "../Header"
import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment"
import { useAuthStatus } from "../../utils/hooks/useAuth"

function Request() {
 
  const [{ cartShow, user }, dispatch] = useStateValue()
  const [dateRange, setDateRange] = useState([null, null]);
  const { data } = useAuthStatus()
  const [startDate, endDate] = dateRange;
  const [error, setError] = useState(false)

  const currentItems = fetchCart()
  const userData = fetchUser()
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    orderUserId: userData.userData?.id,
    orderUser: {
      userId: userData.userData?.id,
      userSName: "",
      userLName: "",
      userFName: "",
      userEmail: "",
      userMob: userData.data?.clientMob
    },
    orderService: [...currentItems],
    orderStartDate: "",
    orderEndDate: "",
    orderServiceTime: "",
    orderServiceLocation: "",
    companyName: "",
    orderMessage: "",
    orderStatus: "Pending",
    orderTotalPrice: "",
    orderWorkerLang: [],
    orderDate: serverTimestamp()
  })
  useEffect(() => {
    window.scrollTo(0, 0);
    if(!currentItems.length){
      navigate('/')
    }
  }, []);
  const serviceTimeOptions = getServiceTimeRef()
  const workerLangOptions = getPreferableLanRef()

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value
    }))
  }
  const onUserChange = (e) => {
    setFormData({
      ...formData,
      orderUser: { ...formData.orderUser, [e.target.id]: e.target.value }
    })
  }

  const handleChange = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      orderServiceTime: selectedOption.value
    }))
  }
  const handleChangeCreatable = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      orderWorkerLang: value
    }))
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    if(!startDate){
      setError(true)
      return
    }
    const formDataCopy = formData
    if(formData.companyName === null || formData.companyName === "" ){
      setFormData(formData.companyName = 'Individual')
    }
    formDataCopy.orderStartDate = startDate
    formDataCopy.orderEndDate = endDate
    // const id = formData.orderUser.userId + Math.floor(Math.random() * 5454544444444444)
      const workerLanguage= formData.orderWorkerLang?.map(data => {
          return(
            data.value
          )
      })
      setFormData(formData.orderWorkerLang = workerLanguage)
      
    try {
      await addDoc(collection(db, "orders"), formData)
    } catch (error) {
      console.log(error)
      toast.error("Something Wrong Please Try Later")
    }
    localStorage.setItem("cartItems", JSON.stringify([]))
    toast.success("Request successfully")
    navigate('/')
  }
  return (
    <>
    <Header sticky={true} />
    <div className="container sm:mx-2 lg:mx-24 mt-24">
      {
        currentItems.length > 0 ?
        (<form onSubmit={onSubmit}>
        <div className="grid grid-cols-12 gap-5">
          <div className="col-span-12 lg:col-span-6">
            <div>
              <h3 className="text-lg font-semibold my-5 text-center">Request Details</h3>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-5 m-5">
                <div className="">
                  <div>
                    <label className="mb-3 inline-block">Name</label>
                    <input
                      className="border border-solid border-gray-300 w-full py-1 px-5 mb-5 placeholder-current text-dark h-12 focus:outline-none text-base"
                      name="userSName"
                      id="userSName"
                      onChange={onUserChange}
                      type="text"
                      defaultValue={data?.clientSName}
                      required
                    />
                  </div>
                </div>
                <div className="">
                  <div>
                    <label className="mb-3 inline-block">Last Name</label>
                    <input
                      className="border border-solid border-gray-300 w-full py-1 px-5 mb-5 placeholder-current text-dark h-12 focus:outline-none text-base"
                      name="userLName"
                      id="userLName"
                      onChange={onUserChange}
                      type="text"
                      defaultValue={data?.clientLName}

                    />
                  </div>
                </div>
                <div className="">
                  <div>
                    <label className="mb-3 inline-block">Family Name</label>
                    <input
                      className="border border-solid border-gray-300 w-full py-1 px-5 mb-5 placeholder-current text-dark h-12 focus:outline-none text-base"
                      name="userFName"
                      id="userFName"
                      onChange={onUserChange}
                      type="text"
                    />
                  </div>
                </div>
                {data?.clientType === 'Organization' && 
                  <div className="">
                    <div>
                      <label className="mb-3 inline-block">Company Name</label>
                      <input
                        className="border border-solid border-gray-300 w-full py-1 px-5 mb-5 placeholder-current text-dark h-12 focus:outline-none text-base"
                        name="companyName"
                        id="companyName"
                        onChange={onChange}
                        type="text"
                      />
                    </div>
                  </div>
                }
                <div className="">
                  <div>
                    <label className="mb-3 inline-block">Location (Service Place)</label>
                    <input
                      className="border border-solid border-gray-300 w-full py-1 px-5 mb-5 placeholder-current text-dark h-12 focus:outline-none text-base"
                      name="orderServiceLocation"
                      id="orderServiceLocation"
                      onChange={onChange}
                      type="text"
                      placeholder="Namibia Street, Bole Atlas"
                      required
                    />
                  </div>
                </div>
                <div className="lg:col-span-1">
                  <div>
                    <label className="mb-3 inline-block">Email</label>
                    <input
                      className="border border-solid border-gray-300 w-full py-1 px-5 mb-5 placeholder-current text-dark h-12 focus:outline-none text-base"
                      name="userEmail"
                      id="userEmail"
                      onChange={onUserChange}
                      type="email"
                      defaultValue={userData.data.clientEmail}
                    />
                  </div>
                </div>
                <div className="">
                  <div>
                    <label className="mb-3 inline-block">For When</label>
                      <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={moment().toDate()}
                        onChange={(update) => {
                          setDateRange(update);
                        }}
                        placeholderText='Check In'
                        isClearable={true}
                        dateFormat="MM/dd/yyyy"
                      />
                      {error && <span className="text-red-500 text-xs pb-5">Start Date is Required</span>}
                  </div>
                </div>

                <div className="">
                  <div>
                    <label className="mb-3 inline-block">Service Time</label>
                    <Select
                      onChange={handleChange}
                      options={serviceTimeOptions}
                    />
                  </div>
                </div>
                <div className="">
                  <div>
                    <label className="mb-3 inline-block">Preferable Worker Language</label>
                    <Select
                      isMulti
                      onChange={handleChangeCreatable}
                      options={workerLangOptions}
                    />
                  </div>
                </div>
              </div>

              <div className="additional-info-wrap mx-4">
                <h4 className="text-base font-semibold">
                  Additional information
                </h4>
                <div className="additional-info">
                  <label className="mb-3 inline-block">Request notes</label>
                  <textarea
                    className="border border-solid border-gray-300 w-full py-1 px-5 mb-5 placeholder-current text-dark h-36 focus:outline-none text-base"
                    placeholder="Notes about your order"
                    name="message"
                    type="textarea"
                    id="orderMessage"
                    onChange={onChange}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-4 mt-4 mt-lg-0">
            <div>
              <h3 className="text-lg font-semibold mb-5 text-center">Your Request</h3>
              <div className="bg-gray-100 p-10">
                <div className="your-order-product-info">
                  <ul className="flex flex-wrap items-center justify-between">
                    <li className="text-base font-semibold">Service</li>
                  </ul>
                  <ul className="border-t border-b border-gray-600 py-5 my-5">
                    {currentItems &&
                      currentItems.map((item) => (
                        <li
                          key={item.id}
                          className="flex flex-wrap items-center justify-between"
                        >
                          <span>
                            {item.serviceTitle} {item.qty}x
                          </span>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div className="mt-6 mb-5 mx-3 md:mx-0">
                <button
                  type="submit"
                  className="block w-full text-center leading-none uppercase bg-black text-white text-sm bg-dark px-5 py-5 transition-all hover:bg-primary font-semibold"
                >
                  Place Order
                </button>
              </div>
            </div>
          </div>
        </div>
        </form>)
        :
        (
         navigate('/')
        )
      }
      {cartShow && <CartContainer />}
     
    </div>
    </>
  )
}

export default Request
