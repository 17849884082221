import React from "react"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
import { EffectFade } from "swiper"

// Import Swiper styles
import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/pagination"
import "swiper/css/effect-fade"
import "swiper/css/navigation"
// import required modules
import { FreeMode, Pagination, Navigation } from "swiper"
import { Link } from "react-router-dom"
import { useCollection } from "../utils/hooks/useCollection"

const HomeContainer = () => {
  const { documents } = useCollection("hero",["heroStatus","==","Active"],["heroOrder","asc"])
  if (!documents) {
    return (
      <div className="relative md:mt-0 postSk">
        <div className="absolute inset-x-0 bottom-0 h-full bg-orange-200" />
        <div className="h-[500px] w-full">
          <div className="relative  sm:overflow-hidden">
            <div className="absolute inset-0">
              <div className="h-full w-full object-cover" />
              <div className="absolute inset-0 bg-orange-200 mix-blend-multiply" />
            </div>
            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="w-full">
      <Swiper
        mousewheel={true}
        effect={"fade"}
        navigation={true}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        loop={true}
        modules={[FreeMode, Pagination, Navigation, EffectFade]}
        className="mySwiper"
      >
        {documents?.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              {" "}
              <div className="relative md:mt-0">
                <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
                <div className="max-h-[500px] w-full">
                  <div className="relative  sm:overflow-hidden">
                    <div className="absolute inset-0">
                      <img
                        className="h-[500px] w-full object-cover"
                        src={item.heroImg}
                        alt={item.heroTitle}
                      />
                      <div className="absolute inset-0 bg-orange-200 mix-blend-multiply" />
                    </div>
                    <div className="relative px-4 py-24 sm:px-6 sm:py-54 lg:py-32 lg:px-8">
                      <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                        <span className="block text-white">{`${
                          item.heroTitle.split(" ")[0]
                        } ${" "} ${item.heroTitle.split(" ")[1]} ${
                          item.heroTitle.split(" ")[2]
                        }`}</span>
                        <span className="block text-secondaryLight">{` ${" "} ${item.heroTitle.split(" ")[3] || ""}${" "} ${
                          item.heroTitle.split(" ")[4] || ""
                        } ${" "} ${
                          item.heroTitle.split(" ")[5] || ""
                        } ${" "} ${
                          item.heroTitle.split(" ")[6] || ""
                        }`}</span>
                      </h1>
                      <p className="hidden md:block mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
                        {item.heroDesc}
                      </p>
                      <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                        {item.heroLink && (
                          <Link
                            to={item.heroLink}
                            className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-secondaryLight bg-opacity-60 hover:bg-opacity-70 sm:px-8"
                          >
                            {item.heroLinkName || "About"}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default HomeContainer
