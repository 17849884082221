import { data } from "autoprefixer"
import { doc, onSnapshot } from "firebase/firestore"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useDocument } from "../../../utils/hooks/useDocument"
import { getTimeStamp } from "../../../utils/utils"
import Spinner from "../../spinner/spinner"

const OrderInvoice = () => {
  const [formData, setFormData] = useState({
    orderUserId: "",
    orderUser: {
      userId: "",
      userSName: "",
      userLName: "",
      userFName: "",
      userEmail: "",
    },
    orderService: {},
    orderServiceDate: "",
    orderServiceTime: "",
    orderServiceLocation: "",
    companyName: "",
    orderMessage: "",
    orderStatus: "Pending",
    orderTotalPrice: "",
    orderDate: "",
  })
  const params = useParams()
  const { isPending, error, documents } = useDocument("orders", params.id)
  console.log(documents?.orderServiceLocation)

  // ** Hooks
  return (
    <>
     {!documents && (
        <div className="flex items-center justify-center mt-56">
          {/* <div
            className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
            role="status"
          > */}
          <Spinner w="100" h="100" />
          {/* </div> */}
        </div>
      )}
    {documents && <div className="flex items-center justify-center min-h-screen pt-5">
        <div className="w-4/5 md:w-3/5 bg-white shadow-lg">
          <div className="flex flex-col md:justify-between md:flex-row p-4">
            <div>
              <h1 className="text-3xl italic font-extrabold tracking-widest text-orange-500">
                Waiter App
              </h1>
              <p className="text-base">
               Lorem ipsum dolor sit amet consectetur.
              </p>
            </div>
            <div className="p-2">
              <ul className="sm:flex-col justify-center md:flex-row">
                <li className="flex flex-col items-center p-2 border-l-2 border-indigo-200">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-orange-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                    />
                  </svg>
                  <span className="text-sm">support@waiterapp.com</span>
                  <span className="text-sm">info@waiterapp.com</span>
                </li>
                <li className="flex flex-col p-2 border-l-2 border-indigo-200">
                  <span className="text-sm">Ethiopia Addis Abeba</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full h-0.5 bg-indigo-500"></div>
          <div className="flex flex-col md:justify-between md:flex-row  p-4">
            <div>
            <h6 className="font-bold">
                Order ID :{" "}
                <span className="text-sm font-medium"> {documents.id}</span>
              </h6>
              <h6 className="font-bold">
                Order Date :{" "}
                <span className="text-sm font-medium"> {getTimeStamp(documents.orderDate)}</span>
              </h6>
            </div>
            <div className="w-40">
              <address className="text-sm">
                <span className="font-bold"> Billed To : </span>
                {`${documents.orderUser.userSName} ${documents.orderUser.userLName}  ${documents.orderUser.userFName} `}
              </address>
            </div>
            <div className="w-40">
              <address className="text-sm">
                <span className="font-bold">Location To :</span>
                {documents.orderServiceLocation}
              </address>
            </div>
            <div></div>
          </div>

          <div className="py-24">
            <div className="mx-2">
              <div className="grid grid-cols-1 gap-x-5">
                <div className="overflow-x-auto items-center">
                  <table className="w-full min-w-max">
                    <thead>
                      <tr>
                        <th className="bg-gray-300 p-3 border border-solid border-gray-600 text-center font-medium text-sm capitalize">
                          Image
                        </th>
                        <th className="bg-gray-300 p-3 border border-solid border-gray-600 text-center font-medium text-sm capitalize">
                          Service
                        </th>
                        <th className="bg-gray-300 p-3 border border-solid border-gray-600 text-center font-medium text-sm capitalize">
                          Price
                        </th>
                        <th className="bg-gray-300 p-3 border border-solid border-gray-600 text-center font-medium text-sm capitalize">
                          Quantity
                        </th>
                        <th className="bg-gray-300 p-3 border border-solid border-gray-600 text-center font-medium text-sm capitalize">
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        documents?.orderService.length > 0 ?(
                          
                            documents?.orderService.map((data) => (
                          <tr key={data.id}>
                            <td className="w-32 p-3 border border-solid border-gray-600 text-center">
                              <div>
                              <img src={data.serviceIcon} className="w-3/5" aria-hidden alt="service image" /> 
                              </div>
                            </td>
                            <td className="p-3 border border-solid border-gray-600 text-center cursor-pointer">
                              <div className="transition-all hover:text-orange-500">
                              {data.serviceTitle}
                              </div>
                            </td>
                            <td className="p-3 border border-solid border-gray-600 text-center">
                              <span>
                                <span>{data.servicePrice || '-'}</span>
                              </span>
                            </td>
                            <td className="p-3 border border-solid border-gray-600 text-center">
                              <span>{data.qty}</span>
                            </td>
  
                            <td className="p-3 border border-solid border-gray-600 text-center">
                              <span>{(data.servicePrice * data.qty) || '-'}</span>
                            </td>
                          </tr>
                        ))) : <Spinner w={50} h={50}/>
                      }
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full h-0.5 bg-orange-500"></div>

          <div className="p-4">
            <div className="flex items-center justify-center">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet,
              magni.
            </div>
            <div className="flex items-end justify-end space-x-3">
              <button className="px-4 py-2 text-sm text-green-600 bg-green-100">
                Print
              </button>
            </div>
          </div>
        </div>
      </div>}
      
    </>
  )
}

export default OrderInvoice
